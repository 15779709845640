import React from 'react';
import { Route, Switch } from 'wouter';
import { Home } from './pages/home';
import { PrivateRoutes, PublicRoutes } from './pages/routes';

function App() {
  console.debug("APP - Rendering", window.location.pathname);
  return (
      <Switch>
        <Route path="/"><Home id="1" /></Route>
        <Route path="/private/:trailingPath*">{(params) => { return (<PrivateRoutes path={params.trailingPath} />); }}</Route>
        <Route>{PublicRoutes}</Route>
      </Switch>
  );
}

export default App;
