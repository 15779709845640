import { Card, Placeholder, Spinner } from "react-bootstrap";

export const PlaceHolderCardBody = () => {
    return (
        <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>
        </Card.Body>
    );
}

export const PlaceHolderCard = ({ children }) => {
    return (
        <Card>
            <Card.Header>{children}</Card.Header>
            <PlaceHolderCardBody />
        </Card>
    )
}

/**
 * 
 * @param {boolean} params.isLoading to render this spinner or not
 * @param {String} params.loadingMessage to display while the spinner is on
 * @returns 
 */
export const SpinnerWhenTrue = ({ isLoading = false, children, loadingMessage = "Executing..." }) => {
    if (isLoading) {
        return (
            <>
                <Spinner as="span" animation="border" role="status" size="sm"/>&nbsp;
                <span>{loadingMessage}</span>
            </>
        )
    }

    //Not loading at the moment
    return children;
}