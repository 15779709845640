import { Alert, Button, Card, Form } from "react-bootstrap";
import { Link, useLocation } from "wouter";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { usePatientContext } from "../../../components/context/patientContext"
import { initSimpleContacts } from "../../../components/domain/party";
import { ApiErrorMessage } from "../../../components/ui/errors";
import { PlaceHolderCardBody } from "../../../components/ui/loading";
import { ControlledNavButton, NavButton } from "../../../components/ui/navigations";
import { PatientCardHeaderview, PatientFormView } from "../../../components/ui/patient";
import { Translate } from "../../../components/ui/text";
import { FormDataHandling } from "../../../components/utils/formUtils";

export const PatientHome = () => {
    const [{ patient, isLoading }, dispatch] = usePatientContext();

    if (!isLoading) {
        return (
            <>
                <Card className="mb-3">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <span><Translate>patient.home.profile</Translate></span>
                        <Link href="/private/patient/edit">
                            <NavButton variant="outline-primary"><i className="bi bi-pencil-square" /> <Translate>generic.edit</Translate></NavButton>
                        </Link>
                    </Card.Header>
                    <div className="my-2 ms-3">
                        <PatientCardHeaderview patient={patient} />
                    </div>
                </Card>
                <Alert variant="dark">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="me-2"><Translate>patient.home.infoNewRecrod</Translate></div>
                        <Link href="/private/patient/records/new">
                            <ControlledNavButton handleClick={() => dispatch({ type: "resetVisit" })} variant="outline-primary">
                                <span  className="text-nowrap ms-1"><i className="bi bi-plus-square" />&nbsp;<Translate>generic.new</Translate></span>
                            </ControlledNavButton>
                        </Link>
                    </div>
                </Alert>
            </>
        )
    }

    return (
        <Card>
            <Card.Header><Translate>patient.home.loading</Translate></Card.Header>
            <PlaceHolderCardBody />
        </Card>
    )
}


export const PatientEditProfileController = ({ patientUid }) => {
    const [{ patient, apiError }, dispatch] = usePatientContext();
    const [, navigate] = useLocation();

    const handleSubmit = (e) => {
        e.preventDefault();

        //Init contact mechanism
        patient.contacts = [patient.email, patient.phone];

        ApiPatientManagement.updatePatient(patient).then((result) => {
            //re-apply the newly updated contacts
            initSimpleContacts(result);

            //Update the state
            dispatch({ type: "patientSaved", value: result });

            //Go back to the profile screen
            navigate("/private/patient/");
        }).catch((err) => {
            dispatch({ type: "apiError", value: err });
        })
    }

    const handleChange = (e) => {
        FormDataHandling.handleOnChange(e, patient);
        dispatch({ type: "patientStateChange", value: patient });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <Form onSubmit={handleSubmit} validated={true}>
                <Card className="mb-3">
                    <Card.Header><span><Translate>patient.home.infoEditContact</Translate></span></Card.Header>
                    <PatientFormView patient={patient} onChange={handleChange} isUpdatedByPatient={true} />
                    <div className="mx-2 mb-2 d-flex justify-content-between">
                        <Link href="/private/patient/">
                            <Button variant="outline-secondary"><Translate>generic.cancel</Translate></Button>
                        </Link>
                        <Button variant="outline-primary" type="submit"><Translate>generic.save</Translate></Button>
                    </div>
                </Card>
            </Form>
        </>

    )
}