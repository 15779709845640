import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { InitRestHelper } from './components/security/security';
import { getLanguage, LocalContextProvider } from './components/context/LocalContext';
import { getBrowserPreferedLanguage } from './components/utils/utils';
import * as Sentry from "@sentry/react";

Sentry.init({
  release: "rwc-ca-v2.0.1",
  environment: "PROD",
  dsn: "https://defe456f94e842c9a4514f8e22ed0d33@o178342.ingest.sentry.io/1264803"
});

/**
 * Callback used by Auth0 after a successfull login.  This callback will bring the user back to where he was when loging in.
 * @param {Object} appState is the object containing the returnTo attribute provided by the login function.
 */
const onRedirectCallback = (appState) => {
  console.debug("App state:", appState, window.location.pathname);

  //Replace the hisotry with the required path
  window.history.replaceState(null, "", appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider domain="rc2i.auth0.com" clientId="kzyEtbQKHj9U9eA3k60W9BkzzbHR0Htn" redirectUri={window.location.origin} audience="https://cogniti2.rc2i.biz/api"
      cacheLocation="localstorage" scope="read:users_app_metadata" useRefreshTokens={true} onRedirectCallback={onRedirectCallback}>
      <LocalContextProvider language={getLanguage() ? getLanguage() : getBrowserPreferedLanguage()}>
        <InitRestHelper>
          <App />
        </InitRestHelper>
      </LocalContextProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
