import { InviteRequestObj } from "./invite";
import { PreferencesMapObj } from "./preference";

export class PhysicianContextValue {
    /**
     * 
     * @param {Array} patientsList list of all patients for this physician 
     * @param {Object} patient the currently selected patient
     * @param {Array} visitsList The visit list of a selected patient
     * @param {Object} visit the currently selected visit
     * @param {Array} pendingInvites the pending invites of a physician
     * @param {Object} invite the currently selected invite
     */
    constructor({ patientsList = [], pendingInvites = [], invite = new InviteRequestObj({}), isInitialized = false, searchedResultFor = "", physician = {}, preferences = new PreferencesMapObj(), sortPreference = ENUM_SortPreferences.desendingRegisteredDate } = {}) {
        this.patientsList = patientsList;
        this.pendingInvites = pendingInvites;
        this.invite = invite;
        this.isInitialized = isInitialized;
        this.searchedResultFor = searchedResultFor;
        this.physician = physician;
        this.preferences = preferences;
        this.sortPreference = sortPreference;
    }

    hasPatients() {
        return this.patientsList && this.patientsList.length > 0;
    }

    hasPendingInvites() {
        return this.pendingInvites && this.pendingInvites.length > 0;
    }

    /**
     * Add the provided invite to the pending invites list
     * @param {Object} invite to add to the pending invites list 
     */
    addPendingInvite(invite) {
        this.pendingInvites.unshift(invite);
    }

    /**
     * 
     * @param {String} uid of the invite to find wihtin the pendingInvite array 
     * @returns the found element, otherwise undefined
     */
    findPendingInviteByUid(uid) {
        return this.pendingInvites.find((inv) => {
            return inv.uid === uid;
        });
    }

    /**
     * Find and return the patient matching the provided uid
     * @param {String} uid of the patient to look for
     * @returns the found patient if any, othwerise null
     */
    getPatientByUid(uid) {
        return this.patientsList.find((p) => {
            return uid === p.uid;
        });
    }

    /**
     * Sort the patientList attribute with the provided predicate.  This is an in place sort, i.e. this object is modified in place.
     * @param {string} sortPredicate 
     * @returns the sorted list
     */
    sortPartientListBy(sortPredicate) {
        if (this.hasPatients) {
            return this.patientsList.sort(sortPredicate);
        }

        return this.patientsList;
    }
}

export function ascendingFullNamePredicate(i1, i2) {
    if (i1.fullName === i2.fullName) {
        return 0
    }

    return i1.fullName > i2.fullName ? 1 : -1;
}

export function decendingFullNamePredicate(i1, i2) {
    if (i1.fullName === i2.fullName) {
        return 0
    }

    return i1.fullName > i2.fullName ? -1 : 1;
}

export function ascendingRegistrationPredicate(i1, i2) {
    if (i1.createdOn === i2.createdOn) {
        return 0
    }

    return i1.createdOn > i2.createdOn ? 1 : -1;
}

export function decendingRegistrationPredicate(i1, i2) {
    if (i1.createdOn === i2.createdOn) {
        return 0
    }

    return i1.createdOn > i2.createdOn ? -1 : 1;
}

export const KEY_SortPreference = "physicianContext.key.sortPreference";

export const ENUM_SortPreferences = {
    assendingFullName: 1,
    desendingFullName: 2,
    ascendingRegisteredDate: 3,
    desendingRegisteredDate: 4
}

export function getSortPreference() {
    const sortPrefValue =  window.localStorage.getItem(KEY_SortPreference);

    return sortPrefValue ? sortPrefValue : ENUM_SortPreferences.desendingRegisteredDate;
}

export function setSortPreference(sortPreference) {
    window.localStorage.setItem(KEY_SortPreference, sortPreference);
}

/**
 * 
 * @param {String} sortPreference is a value of ENUM_SortPreferences.
 * @returns a predicate function corressponding to the provided enum value
 */
export function factorySortPredicate(sortPreference) {
    switch (sortPreference) {
        case ENUM_SortPreferences.assendingFullName:
            return ascendingFullNamePredicate;
        case ENUM_SortPreferences.desendingFullName:
            return decendingFullNamePredicate;
        case ENUM_SortPreferences.ascendingRegisteredDate:
            return ascendingRegistrationPredicate;
        default:
            return decendingRegistrationPredicate;
    } 
}