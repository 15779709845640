/**
 * Utility class to help with automating form managment
 * 
 * Two main methods to use are
 *   * handleOnChange - Help with controled component</li>
 *   * handleFormSubmit - help with uncontrolled components using native HTML5 form validation
 */
class FormDataHandlingUtility {

    /**
     * Find if the field name is in error.
     * @param {String} fieldName the field name to look for wihtin the validationErrors array
     * @param {Object} validationErrors a JSON representation of org.rc2i.utilities.validators.ValidationErrors
     */
    isFieldInError(fieldName, validationErrors) {
        if (validationErrors) {
            for (var idx = 0; idx < validationErrors.length; idx++) {
                if (validationErrors[idx].fieldName === fieldName) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Returns the error code for the provided field name, if not found return the provided default code
     * @param {String} fieldName to look for
     * @param {Object} validationErrors object containning all errors
     * @param {String} defaultMessage the default error code to return when none is found.
     */
    getErrorCodeFor(fieldName, validationErrors, defaultErrorCode) {
        if (validationErrors) {
            for (let value in validationErrors) {
                if (fieldName === value.fieldName) {
                    return value.errorCode;
                }
            }
        }
        return defaultErrorCode;
    }

    /**
     * Main method to use in a form to handle onChange events
     * @param {Event} event object comming from the form
     * @param {Object} formObject The form object usually stored in the state
     * @returns {Object} the form object to be put back in the state
     */
    handleOnChange(event, formObject) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.assignValue(name, formObject, value);

        return formObject;
    }

    /**
     * Main method to use when you want to handle data on form submision.
     * Especially usefull for HTML5 form
     * @param {HTMLFormElement} formElement targeted object to copy the form data to
     * @param {Object} targetObject is the object to update with the data from the provided formElement
     * @returns {Object} the updated form object
     */
    handleFormSubmit(formElement, targetObject) {
        const data = new FormData(formElement);

        for (let name of data.keys()) {
            const input = formElement.elements[name];
            const parserName = input.dataset.parser;
            const value = this.parseValue(parserName, data.get(name));
            this.assignValue(name, targetObject, value);
        }
        return targetObject;
    }

    /**
     * Parse the provided value using the requested parser.  If no parser is found, we simply return the value
     * @param {String} name of the parser to use
     * @param {*} value to parse
     */
    parseValue(name, value) {
        //Implement parser detection here
        return value;
    }

    /**
     * 
     * @param {*} name 
     * @param {*} targetObject 
     * @param {*} value 
     */
    assignValue(name, targetObject, value) {
        //Assign the value to the right attributes within the form object
        if (name.includes(".")) { //Nested attributes
            this.setNestedAttribute(name, targetObject, value);
        } else { //Standard attribute
            this.setAttributeValue(targetObject, name, value);
        }
    }

    setAttributeValue(parent, child, value) {
        if (!parent[child]) {
            Object.defineProperty(parent, child, { value: null, writable: true, enumerable: true });
        }

        parent[child] = value;
    }

    /**
     * Utility method to extract nested attributes of an object.
     *
     * @param {String} attributePath is a series of attribute name separated with a '.'  For example "contact.value"
     * @param {Object} sourceObject is the object to parse the nested attribute from.
     */
    setNestedAttribute(attributePath, sourceObject, value) {

        // Construct an array of all nested attributes.  ex: "contact.value".split('.') => ["contact", "value"]
        const attributes = attributePath.split('.');

        // The last attribute would be "value";
        const childAttr = attributes.pop();

        //Parse all attributes up to the before last level.
        // const parentAttr = nestedAttributes.reduce((parentAttr, attrName) => (parentAttr && parentAttr[attrName]) ? parentAttr[attrName] : null, sourceObject);
        //const parentAttr = nestedAttributes.reduce(this.callBackNestedAttribute, sourceObject);
        const parentAttr = attributes.reduce(this.callBackNestedAttribute, sourceObject);

        this.setAttributeValue(parentAttr, childAttr, value);
    }

    /**
     * Verify if the current attribute has the provided child attribute, Otherwise create the child attribute and return it
     * @param {*} currentAttr the current nested attribute
     * @param {*} childAttr the child attribute to return.  It will become the current attribute at the next call.
     */
    callBackNestedAttribute(currentAttr, childAttr) {
        if (currentAttr) {
            if (!currentAttr[childAttr]) {
                Object.defineProperty(currentAttr, childAttr, { value: {}, writable: true, enumerable: true });
            }
            return currentAttr[childAttr]
        }
        return null;
    }
}

export const FormDataHandling = new FormDataHandlingUtility();