import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react"
import { Accordion, Alert, Button, Card, Modal } from "react-bootstrap";
import { ApiInviteManagement } from "../../../components/api/apiInviteManagement"
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { ApiErrorMessage } from "../../../components/ui/errors";
import { PlaceHolderCardBody } from "../../../components/ui/loading";
import { Clickable } from "../../../components/ui/navigations";
import { Translate } from "../../../components/ui/text";

export const TokenValidationController = ({ tokenId }) => {
    const [registrationCtx, setCtx] = useState({});

    useEffect(() => {
        if (tokenId) {
            setCtx({ isApiCallOn: true });

            ApiInviteManagement.validateToken(tokenId).then((invReq) => {
                setCtx({ invite: invReq, token: tokenId });
            }).catch((error) => {
                setCtx({ apiError: error });
            });
        }
    }, [tokenId]);

    //Token errors
    if (registrationCtx.apiError) {
        if (registrationCtx.apiError.status === 404) {
            return (
                <Alert variant="warning">
                    <Alert.Heading><Translate>patient.registration.error.invalidToken</Translate></Alert.Heading>
                    <p><Translate>patient.registration.error.text1</Translate></p>
                    <p><Translate>patient.registration.error.text2</Translate></p>
                </Alert>
            )
        }
        return (
            <ApiErrorMessage apiError={registrationCtx.apiError} />
        );
    }

    if (registrationCtx.isApiCallOn) {
        return (
            <Card>
                <Card.Header><Translate>patient.registration.lookingForInvite</Translate></Card.Header>
                <PlaceHolderCardBody />
            </Card>
        )
    }

    //Display the registration process
    if (registrationCtx.invite) {
        return (
            <RegistrationViewController invite={registrationCtx.invite} />
        );
    }

    //This should not happen
    return null;
}

const RegistrationViewController = ({ invite }) => {
    const { loginWithRedirect, loading } = useAuth0();
    const [toDisplay, setToDisplay] = useState(false);
    const [toDisplayPP, setToDisplayPP] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        e.stopPropagation();
        loginWithRedirect({ screen_hint: "signup", appState: { returnTo: `/registered/${invite.inviteToken}` } });
    };

    return (
        <>
            <Card>
                <Card.Header><Translate>generic.hello</Translate> {invite.lastName}, {invite.firstName}</Card.Header>
            </Card>
            <TermsAndCondition show={toDisplay} onClick={setToDisplay} />
            <PrivacyPolicy show={toDisplayPP} onClick={setToDisplayPP} />
            <p className="mt-2"><Translate>patient.registration.text1</Translate></p>
            <p><Translate>patient.registration.text2</Translate></p>
            <Accordion>
                <Accordion.Item eventKey="tc">
                    <Accordion.Header><Translate>patient.registration.tcPolicy.title</Translate></Accordion.Header>
                    <Accordion.Body>
                        <Translate>patient.registration.tcPolicy.acceptMessage</Translate>:
                        <ul>
                            <li><Clickable onClick={(e) => { e.preventDefault(); setToDisplay(!toDisplay); }}><Translate>patient.registration.tc</Translate></Clickable></li>
                            <li><Clickable onClick={(e) => { e.preventDefault(); setToDisplayPP(!toDisplayPP); }}><Translate>patient.registration.privacyPolicy</Translate>Privacy policy</Clickable></li>
                        </ul>

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="browser">
                    <Accordion.Header><Translate>patient.registration.browserCompatibility</Translate></Accordion.Header>
                    <Accordion.Body>
                        <Translate>patient.registration.browserCompatibility.supportMessage</Translate>:
                        <ul>
                            <li>Chrome</li>
                            <li>Safari</li>
                            <li>Firefox</li>
                            <li>Edge</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className="d-flex justify-content-between my-3">
                <Button variant="outline-secondary" href="/"><Translate>generic.cancel</Translate></Button>
                <Button variant="outline-primary" disabled={loading} onClick={handleRegister}><Translate>generic.register</Translate></Button>
            </div>
        </>
    )
}

function PrivacyPolicy({ show = false, onClick = null }) {
    return (
        <Modal show={show} onHide={() => onClick(false)} scrollable={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><Translate>patient.registration.privacyPolicy</Translate></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h1>Privacy Policy</h1>
                <p>Last updated: June 17, 2022</p>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank" rel="nofollow noopener noreferrer" >TermsFeed Privacy Policy Generator</a>.</p>
                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h2>Definitions</h2>
                <p>For the purposes of this Privacy Policy:</p>
                <ul>
                    <li>
                        <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                    </li>
                    <li>
                        <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to RC2i INC, 714 rue Blain, Sainte-Julie, QC, J3E 1K1, Canada.</p>
                    </li>
                    <li>
                        <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                    </li>
                    <li>
                        <p><strong>Country</strong> refers to: Quebec,  Canada</p>
                    </li>
                    <li>
                        <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                    </li>
                    <li>
                        <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                    </li>
                    <li>
                        <p><strong>Service</strong> refers to the Website.</p>
                    </li>
                    <li>
                        <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                    </li>
                    <li>
                        <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    </li>
                    <li>
                        <p><strong>Website</strong> refers to Remote wound care, accessible from <a href="https://www.remotewoundcare.ca" rel="external nofollow noopener noreferrer" target="_blank" >https://www.remotewoundcare.ca</a></p>
                    </li>
                    <li>
                        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                </ul>
                <h1>Collecting and Using Your Personal Data</h1>
                <h2>Types of Data Collected</h2>
                <h3>Personal Data</h3>
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                <ul>
                    <li>
                        <p>Email address</p>
                    </li>
                    <li>
                        <p>First name and last name</p>
                    </li>
                    <li>
                        <p>Phone number</p>
                    </li>
                    <li>
                        <p>Health record number</p>
                    </li>
                    <li>
                        <p>Usage Data</p>
                    </li>
                </ul>
                <h3>Usage Data</h3>
                <p>Usage Data is collected automatically when using the Service.</p>
                <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                <h3>Tracking Technologies and Cookies</h3>
                <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                <ul>
                    <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                    <li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot; available at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" rel="external nofollow noopener noreferrer" target="_blank">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a></li>
                    <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                </ul>
                <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank" rel="nofollow noopener noreferrer" >TermsFeed website</a> article.</p>
                <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <ul>
                    <li>
                        <p><strong>Necessary / Essential Cookies</strong></p>
                        <p>Type: Session Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                    </li>
                    <li>
                        <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                    </li>
                    <li>
                        <p><strong>Functionality Cookies</strong></p>
                        <p>Type: Persistent Cookies</p>
                        <p>Administered by: Us</p>
                        <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                    </li>
                </ul>
                <h2>Use of Your Personal Data</h2>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                    <li>
                        <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                    </li>
                    <li>
                        <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                    </li>
                    <li>
                        <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                    </li>
                    <li>
                        <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                    </li>
                    <li>
                        <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                    </li>
                </ul>
                <p>We may share Your personal information in the following situations:</p>
                <ul>
                    <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                    <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                </ul>
                <p>We do not sell or share any of your personal information to any third parties.  All personal information are kept and used by us to fullfil the services provided by this website.</p>
                <h2>Retention of Your Personal Data</h2>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                <h2>Transfer of Your Personal Data</h2>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                <h2>Disclosure of Your Personal Data</h2>
                <h3>Business Transactions</h3>
                <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <h3>Law enforcement</h3>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h3>Other legal requirements</h3>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of the Company</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>Protect the personal safety of Users of the Service or the public</li>
                    <li>Protect against legal liability</li>
                </ul>
                <h2>Security of Your Personal Data</h2>
                <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <h1>Children's Privacy</h1>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <h1>Links to Other Websites</h1>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h1>Changes to this Privacy Policy</h1>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h1>Contact Us</h1>
                <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                <ul>
                    <li>By email: info@rc2i.ca</li>
                </ul>
            </Modal.Body>
        </Modal>
    );
}

function TermsAndCondition({ show = false, onClick = null }) {
    return (
        <Modal show={show} onHide={() => onClick(false)} scrollable={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title><Translate>patient.registration.tc</Translate></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h1>Terms and conditions</h1>
                <p>These terms and conditions (&quot;Agreement&quot;) sets forth the general terms and conditions of your use of the <a target="_blank" rel="nofollow noopener noreferrer" href="https://rwc.rc2i.biz">rwc.rc2i.biz</a> website (&quot;Website&quot; or &quot;Service&quot;) and any of its related products and services (collectively, &quot;Services&quot;). This Agreement is legally binding between you (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and RC2i INC. (&quot;RC2i INC.&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms &quot;User&quot;, &quot;you&quot; or &quot;your&quot; shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and RC2i INC., even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</p>
                <h2>Accounts and membership</h2>
                <p>If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.</p>
                <h2>Links to other resources</h2>
                <p>Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website and Services. Your linking to any other off-site resources is at your own risk.</p>
                <h2>Prohibited uses</h2>
                <p>In addition to other terms as set forth in the Agreement, you are prohibited from using the Website and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Website and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Website and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Website and Services for violating any of the prohibited uses.</p>
                <h2>Intellectual property rights</h2>
                <p>&quot;Intellectual Property Rights&quot; means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by RC2i INC. or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with RC2i INC. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of RC2i INC. or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of RC2i INC. or third party trademarks.</p>
                <h2>Contents</h2>
                <p>If you registered, you have the right to upload content that is related to the service provided by this website. You may not use our Products to do or share anything:</p>
                <ul>
                    <li>That violates these Terms</li>
                    <li>That is unlawful, misleading, discriminatory or fraudulent</li>
                    <li>That infringes or violates someone else's rights, including their intellectual property rights</li>
                    <li>You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our Products</li>
                    <li>You may not access or collect data from our Products using automated means (without our prior permission) or attempt to access data you do not have permission to access</li>
                    <li>We can remove or restrict access to content that is in violation of these provisions.</li>
                </ul>
                <h2>Rights you grant us</h2>
                <p>You retain ownership rights in your Content. However, we do require you to grant certain rights to RC2i INC, as described below</p>
                <h4>License to RC2i INC.</h4>
                <p>By providing Content to the Service, you grant to RC2i INC a worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use that Content (including to reproduce, distribute, prepare derivative works, display and perform it) in connection with the Service and RC2i INC's health institutions affiliates, for the purpose of redistributing part or all of the Service.</p>
                <h2>Disclaimer of warranty</h2>
                <p>You agree that such Service is provided on an &quot;as is&quot; and &quot;as available&quot; basis and that your use of the Website and Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.</p>
                <h2>Limitation of liability</h2>
                <p>To the fullest extent permitted by applicable law, in no event will RC2i INC., its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of RC2i INC. and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to RC2i INC. for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.</p>
                <h2>Indemnification</h2>
                <p>You agree to indemnify and hold RC2i INC. and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website and Services or any willful misconduct on your part.</p>
                <h2>Severability</h2>
                <p>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.</p>
                <h2>Dispute resolution</h2>
                <p>The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Quebec, Canada without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Canada. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Quebec, Canada, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</p>
                <h2>Assignment</h2>
                <p>You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.</p>
                <h2>Changes and amendments</h2>
                <p>We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</p>
                <h2>Acceptance of these terms</h2>
                <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.</p>
                <h2>Contacting us</h2>
                <p>If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to &#105;&#110;f&#111;&#64;&#114;&#99;&#50;&#105;.bi&#122;</p>
                <p>This document was last updated on September 8, 2020</p>
            </Modal.Body>
        </Modal>
    );
}

export const RegisteredController = ({ tokenId }) => {
    const { loginWithRedirect } = useAuth0();
    const [registeredCtx, setCtx] = useState({});

    useEffect(() => {
        if (tokenId) {
            setCtx({ isApiCallOn: true });
            ApiPatientManagement.registerNewPatient(tokenId).then((userIdentity) => {
                setCtx({ userIdentity: userIdentity });
            }).catch((err) => {
                setCtx({ apiError: err });
            });
        }
    }, [tokenId]);

    const handleClick = () => loginWithRedirect({ prompt: "none", appState: { returnTo: "/private/patient/" } });

    //Registration being processed
    if (registeredCtx.isApiCallOn) {
        return (
            <Card>
                <Card.Header><Translate>patient.registration.completingRegistration</Translate></Card.Header>
                <PlaceHolderCardBody />
            </Card>
        );
    }

    //Registration successfull
    if (registeredCtx.userIdentity) {
        return (
            <Card>
                <Card.Header><Translate>patient.registration.success.title</Translate></Card.Header>
                <Card.Body>
                    <p><Translate>patient.registration.success.text1</Translate></p>
                    <p><small className="text-muted"><Translate>patient.registration.success.text2</Translate></small></p>
                    <div className="d-flex justify-content-center">
                        <Button variant="outline-primary" onClick={handleClick}><Translate>patient.registration.success.navigateToMyProfile</Translate></Button>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    //Registration error
    if (registeredCtx.apiError) {
        return (
            <ApiErrorMessage apiError={registeredCtx.apiError} />
        );
    }

    //Shoudl not happen
    return null;
}