import { VisitObj } from "./visit";

/**
 * @param {Object} patient to test its registration
 * @returns true if the provided patient's state has a value of "registered", otherwise false
 */
export function isRegisteredPatient(patient) {
    if (patient && patient.state && patient.state.value) {
        return patient.state.value !== "prospect";
    }
    return false;
}

export class PatientContextValue {

    /**
     * Create a new patient context object with its initial value
     * @param {Object} patient is the current patient being worked on
     * @param {Array} visitsList  are the visits associated with the current patient
     * @param {Object} visit is the currently selected visit
     * @param {Array} chat are the chats associated with the patient
     * @param {String} patientUid is the identifier of the loaded patient
     */
    constructor({ patient = {}, oldPatient = null, visits = [], visit = new VisitObj({}), chat = null, patientUid="" }) {
        this.patient = patient; 
        if (oldPatient) {
            this.oldPatient = oldPatient;
        } else {
            this.oldPatient = {...patient};
            this.oldPatient.email = {...patient.email};
            this.oldPatient.phone = {...patient.phone};
        }
        this.visits = visits;
        this.visit = visit;
        this.chat = chat;
        this.patientUid = patientUid;
    }

    hasVisits() {
        return this.visits && this.visits.length > 0;
    }

    hasChatMessages() {
        return this.chat && this.chat.hasMessages && this.chat.hasMessages();
    }

    /**
     * Set the provided patient into this context and also set the patientUid to the same value as the provided patient.
     * 
     * @param {Object} patient to load into this context
     */
    setLoadedPatient(patient) {
        this.patient = patient;
        this.patientUid = patient.uid;
    }

    /**
     * 
     * @returns true if the current patient attribute has the same patient uid as the patientUid attribute, otherwise false
     */
    isPatientLoaded() {
        return this.patient && this.patient.uid === this.patientUid
    }

    /**
     * 
     * @returns true if the current context has an attribute apiError set, otherwise false.
     */
    hasApiError() {
        return this.apiError ? true : false;
    }

    /**
     * 
     * @param {Object} visit to update this context with
     */
    addOrReplaceVisit(visit) {
        this.visit = visit;
        const idx = this.visits.findIndex((v) => v?.uid === visit?.uid);
        
        //Replace the object if found
        if (idx > -1) {
            this.visits[idx] = visit;
            return;
        }

        //Add the object since not found
        this.visits.unshift(visit);
    }
}