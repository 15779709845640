import React, { useEffect } from "react";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { useAuthenticatedUserContext } from "../../../components/context/authenticatedUser";
import { usePatientContext } from "../../../components/context/patientContext";
import { ChatView } from "../../../components/ui/chat";
import { ApiErrorMessage, ApiSuccessMessage } from "../../../components/ui/errors";
import { Translate } from "../../../components/ui/text";

export const PhysicianChatController = () => {
    const [{ apiError, patientUid, chatMessageSent, chat, chatMessage = "", isSendingMessage = false }, dispatch] = usePatientContext();
    const [user] = useAuthenticatedUserContext();

    useEffect(() => {
        if (patientUid) {
            ApiPatientManagement.getPatientChat(patientUid).then((result) => {
                dispatch({ type: "chatStateChange", value: result });
            }).catch((result) => {
                dispatch({ type: "apiError", value: result });
            })
        }
    }, [patientUid]);

    const handleSendMessage = (msg) => {
        dispatch({ type: "sendingChatMessage" });
        ApiPatientManagement.postChatMessage(patientUid, msg).then((result) => {
            dispatch({ type: "messageSent", value: result });
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        })
    }

    const handleChatMessageChange = (msgVal) => {
        dispatch({ type: "chatMessageStateChange", value: msgVal });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <ApiSuccessMessage isShowing={chatMessageSent}><Translate>physician.patient.chat.apiSuccess</Translate></ApiSuccessMessage>
            <ChatView chat={chat} message={chatMessage} onSend={handleSendMessage} senderPartyUid={user.personUid} onChange={handleChatMessageChange} isMessageBeingSent={isSendingMessage} />
        </>
    );
}