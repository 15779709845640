import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ApiInviteManagement } from "../api/apiInviteManagement";
import { ApiPatientManagement } from "../api/apiPatientManagement";
import { ApiPhysicianManagement } from "../api/apiPhysicianManagement";
import { ApiPreferenceManagement } from "../api/apiPreferenceManagement";
import { convertRawInvitesToInviteObjects, InviteRequestObj } from "../domain/invite";
import { PhysicianContextValue, factorySortPredicate, getSortPreference, setSortPreference } from "../domain/physician";
import { PhysicianDownForMaintenance } from "../ui/errors";
import { PlaceHolderCard } from "../ui/loading";
import { replaceElementWith } from "../utils/utils";

const defaultReducer = (currentState, action) => {
    const newCtx = new PhysicianContextValue(currentState);
    console.debug("PhysicianContext-Reducer - action:", action, newCtx);
    switch (action.type) {
        case "initPhysicianContext":
            newCtx.isInitialized = true;
            newCtx.patientsList = action.patients;
            newCtx.pendingInvites = action.invites;
            newCtx.physician = action.physician;
            newCtx.preferences = action.preferences;
            newCtx.sortPartientListBy(action.sortPredicate);
            return newCtx;
        case "loadPatient":
            newCtx.patient = action.value;
            return newCtx;
        case "inviteStateChange":
            newCtx.invite = action.value;
            return newCtx;
        case "newInviteCreated":
            newCtx.invite = new InviteRequestObj({});
            newCtx.addPendingInvite(new InviteRequestObj(action.value));
            return newCtx;
        case "inviteUpdated":
            newCtx.invite = new InviteRequestObj({});
            replaceElementWith(newCtx.pendingInvites, new InviteRequestObj(action.value));
            return newCtx;
        case "resetInivite":
            newCtx.invite = new InviteRequestObj({});
            return newCtx;
        case "searchResult":
            newCtx.patientsList = action.value;
            newCtx.searchedResultFor = action.searchedResultFor;
            return newCtx;
        case "isApiCallActive":
            newCtx.isApiCallActive = true;
            return newCtx;
        case "apiError":
            newCtx.apiError = action.value;
            return newCtx;
        case "initError":
            newCtx.apiError = action.value;
            newCtx.isInitialized = true;
            newCtx.initError = true;
            return newCtx;
        case "sortPatients":
            newCtx.patientsList.sort(factorySortPredicate(action.sortPreference));
            newCtx.sortPreference = action.sortPreference;
            setSortPreference(action.sortPreference);
            return newCtx;
        default:
            console.error("Unknown reducer action", action);
            break;
    }
}

const PhysicianContext = createContext({});

/**
 * Calls the API to get all patients of the currently authenticated physician
 * @param {Object} args supplied by the useReducer function. 
 * @returns the newly created Physician context
 */
const initPhysicianContext = async (dispatch) => {
    console.debug("initPhysician - Initializing physician's context")

    try {
        const sortPreference = getSortPreference();
        const sortPredicate = factorySortPredicate(sortPreference);
        const pList = await ApiPatientManagement.getAllPatientsOfAPhysician();
        const pendingInvites = await ApiInviteManagement.getPendingInvites();
        const phys = await ApiPhysicianManagement.getAuthenticatedPhysician();
        const pref = await ApiPreferenceManagement.getPreferencesForAuthOfDoamin("rwc.pwa");
        dispatch({ type: "initPhysicianContext", patients: pList, invites: convertRawInvitesToInviteObjects(pendingInvites), physician: phys, preferences: pref, sortPredicate: sortPredicate, sortPreference: sortPreference });
    } catch (error) {
        dispatch({ type: "initError", value: error });
        console.error("initPhysicianContext", error);
    }
}

/**
 * Provide the context container for Physician
 * @param {Object} physician object from the IDP to build our internal user from 
 * @param {Object} children is the React children to render if the user is authenticated
 * @param {Function} loadPhysician is a callback function to lazy create the Physician.
 * @returns the provided children
 */
export const PhysicianContextProvider = ({ children }) => {
    const ctx = useReducer(defaultReducer, new PhysicianContextValue());
    const [state, reducer] = ctx;

    useEffect(() => {
        if (!state.isInitialized) {
            initPhysicianContext(reducer);
        }
    }, [state.isInitialized]); //State object cannot be part of the array by itself as it changes everytime we update its content.

    if (state.isInitialized && state.initError) {
        return (<PhysicianDownForMaintenance />);
    }

    if (state.isInitialized) {
        return (
            <PhysicianContext.Provider value={ctx}>
                {children}
            </PhysicianContext.Provider>
        );
    }

    //Loading
    return (
        <PlaceHolderCard>Loading phsysician info...</PlaceHolderCard>
    )
}

export const usePhysicianContext = () => useContext(PhysicianContext);