import React from "react"
import { Button, Nav, useAccordionButton, Pagination } from "react-bootstrap"
import { Link } from "wouter"
import makeMatcher from "wouter/matcher"
import { dummyFunction } from "../utils/utils"

/**
 * 
 * @param {String} href to link to when clicking on the buttong
 * @param {Function} onClick to execute when clicking on the button
 * @param {Ojbect} to render in the button 
 * @returns 
 */
export const NavButton = ({ href = "", children, className = "", ...others }) => {
  return (
    <Button  {...others} className={className + " py-1"} href={href}>{children}</Button>
  )
}

/**
 * Renders a controlled NavButton component that can handle clicks beofre the actual NavButton
 * @param {Function} param.onClick is the function that will be called last.  Not required if the parent is a <Link> component
 * @param {Function} param.handleClick is function that will be called first when the user clicks the navigation link.
 * @returns the rendered <NavButton> component
 */
export const ControlledNavButton = ({ onClick, handleClick, ...others }) => {
  return (
    <div onClick={(e) => { handleClick(e); onClick(e) }}>
      <NavButton {...others} />
    </div>
  )
}

/**
 *  Renders a controlled <Nav.Link> component that can handle clicks beofre the actual <Nav.Link>
 * @param {Function} param.onClick is the function that will be called last.  Not required if the parent is a <Link> component
 * @param {Function} param.handleClick is function that will be called first when the user clicks the navigation link.
 * @returns the rendered <Nav.Link> component
 */
export const ControlledNavLink = ({ onClick, handleClick, ...others }) => {
  return (
    <div onClick={(e) => { (handleClick && handleClick(e)); onClick(e) }}>
      <Nav.Link {...others} />
    </div>
  )
}

export const AccordionButton = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <Button onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}

/**
 * 
 * @param {String} pathRegex Wooter regex path to match agains the current location
 * @returns True if the provided path regex matches the current path location.
 */
export const isThisPathActive = (pathRegex) => {
  const matcher = makeMatcher();
  return matcher(pathRegex, window.location.pathname)[0];
}

/**
 * Navigate to a different page.  This will simply replace the browser history with the provided path.
 * @param {String} path to navigate to 
 */
export function navigate(path, replace = true) {
  if (path) {
    if (replace) {
      window.history.replaceState(null, "", path);
    } else {
      window.history.pushState(null, "", path);
    }
  }
}

/**
 * Render a cliakable element with a callback function on click
 * @param {function} onClick will be called when the user click on this element 
 * @param {function} children will be displayed as a clickable element
 */
export function Clickable({ onClick = null, children, ...others }) {
  if (onClick) {
    return (<a href="#top" {...others} onClick={onClick}>{children}</a>);
  }
  return null;
}

/**
 * @param {Number} numberOfItems to render in pages
 * @param {Number} params.maxItems of patient to display in a list
 * @param {Number} params.currentPage Which page to display
 * @param {Function} params.onChangePage callback when the page changes 
 */
export const PageNavigator = ({ numberOfItems = 0, maxItems = 15, currentPage = 0, baseUrl = "/page/not/provided" }) => {
  const maxNoOfPages = Math.ceil(numberOfItems / maxItems);

  if (maxNoOfPages > 1) {
    const pages = [];
    for (let idx = 0; idx < maxNoOfPages; idx++) {
      pages.push((
        <Link key={idx} href={baseUrl + "?pageNo=" + idx}>
          <Pagination.Item className="mx-1" active={(idx === currentPage)}>{idx + 1}</Pagination.Item>
        </Link>
      ));
    }

    return (
      <Pagination className="m-2 d-flex justify-content-center">
        <Link href={baseUrl + "?pageNo=" + (currentPage - 1)}>
          <Pagination.Prev className="mx-3" disabled={currentPage === 0} />
        </Link>
        {pages}
        <Link href={baseUrl + "?pageNo=" + (currentPage + 1)}>
          <Pagination.Next className="mx-3" disabled={currentPage === (maxNoOfPages - 1)} />
        </Link>
      </Pagination>
    )
  }

  //Nothing to render
  return null;
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
 export function PagingListItemsMap({items, maxItems = 15, currentPage = 0, children = dummyFunction} ) {

  //Render found patients
  if (items && items.length > 0) {
      const maxNoOfPages = Math.ceil(items.length / maxItems);

      //Assign the start of the idx within the patients array, which represent the desired page.
      const startOfPageIdx = currentPage >= maxNoOfPages ? maxNoOfPages * maxItems : currentPage * maxItems;

      const results = [];

      for (let idx = 0; (idx < maxItems) && ((idx + startOfPageIdx) < items.length); idx++) {
          //console.log("Pages", idx, items.length, startOfPageIdx, currentPage)
          results.push(children(items[idx + startOfPageIdx]));
      }

      return results;
  }

  //Nothing to render
  return null;
}