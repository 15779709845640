import { Card, FloatingLabel, Form } from "react-bootstrap";
import { emptyIfUndefined } from "../utils/utils";
import { PartyEmailView, PartyPhoneView, RenderIfContactMechanismPresents, } from "./PartyElements";
import { Translate } from "./text";
import { useLocalContext } from "../context/LocalContext";
import moment from "moment";

export const PatientCardHeaderview = ({ patient }) => {
    return (
        <>
            <Card.Title className="text-capitalize">{patient.fullName} <small className="text-muted">({patient.healthRecordUid})</small></Card.Title>
            <Card.Subtitle>
                <div className="mt-2 d-flex flex-wrap">
                    <RenderIfContactMechanismPresents cm={patient.emails}>
                        <div className="me-3"><PartyEmailView party={patient} />, </div>
                    </RenderIfContactMechanismPresents>
                    <RenderIfContactMechanismPresents cm={patient.phones}>
                        <div><PartyPhoneView party={patient} /></div>
                    </RenderIfContactMechanismPresents>
                </div>
                <div className="mt-2"><small className="text-muted"><Translate>generic.createdOn</Translate>: {moment(patient.createdOn).format("MMM Do, YYYY")}</small></div>
            </Card.Subtitle>

        </>
    );
}



export const PatientFormView = ({ patient, onChange, isUpdatedByPatient = false, isHealthRecordValid = true, hrnMaxLength = 20, hrnInputMode="text", hrnPattern="[A-Za-z0-9]{1,20}" }) => {
    const [{i18n}] = useLocalContext();

    return (

        <div className="m-2">
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.healthRecordUid" label={i18n.getEntry("generic.healthRecordNumber")+" #"} >
                    <Form.Control type="text" pattern={hrnPattern} maxLength={hrnMaxLength} isInvalid={!isHealthRecordValid} isValid={isHealthRecordValid} inputMode={hrnInputMode} name="healthRecordUid" placeholder={i18n.getEntry("generic.healthRecordNumber")+" #"} required={true} value={emptyIfUndefined(patient.healthRecordUid)} readOnly={isUpdatedByPatient} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>view.patient.invalid.hrnMustBeValid</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.firstName" label={i18n.getEntry("generic.firstName")}>
                    <Form.Control type="text" name="firstName" required maxLength={50} isValid={patient.firstName} isInvalid={!patient.firstName} autoComplete="given-name" placeholder={i18n.getEntry("generic.firstName")} value={emptyIfUndefined(patient.firstName)} readOnly={isUpdatedByPatient} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>view.patient.invalid.valueRequired</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.lastName" label= {i18n.getEntry("generic.lastName")}>
                    <Form.Control type="text" name="lastName" required maxLength={50} isValid={patient.lastName} isInvalid={!patient.lastName} autoComplete="family-name" placeholder={i18n.getEntry("generic.lastName")} value={emptyIfUndefined(patient.lastName)} readOnly={isUpdatedByPatient} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>view.patient.invalid.valueRequired</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.phone" label={i18n.getEntry("generic.phone")} >
                    <Form.Control type="tel" name="phone.phone" maxLength={20} autoComplete="tel-national" placeholder={i18n.getEntry("generic.phone")} value={emptyIfUndefined(patient?.phone?.phone)} onChange={onChange} />
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.email" label={i18n.getEntry("generic.email")} >
                    <Form.Control type="email" name="email.email" maxLength={320} autoComplete="email" placeholder={i18n.getEntry("generic.email")} value={emptyIfUndefined(patient?.email?.email)} onChange={onChange} />
                </FloatingLabel>
            </Form.Group>
        </div>
    )
}