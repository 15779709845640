import { PreferencesMapObj } from '../domain/preference';
import { ApiHelper } from './RestHelper';

class PreferenceManagementApi {

    constructor() {
        this.apiBaseRoute = "/bff/preference-management/v1.0";

        this.preferencesForAuthenticatedUserUri = `${this.apiBaseRoute}/preferences/authenticated`;
    }

    /**
     * Returns the requested visit
     * @param {*} visitUid identifier of the visit to look for
     */
    async getPreferencesForAuthOfDoamin(domain) {
        return ApiHelper.get(`${this.preferencesForAuthenticatedUserUri}/${domain}`).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(new PreferencesMapObj(apiResp.dataObject));
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }
}

export const ApiPreferenceManagement = new PreferenceManagementApi();