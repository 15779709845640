import React, { useState } from "react";
import { Alert, Button, Card, Dropdown, Form, FormControl, InputGroup, ListGroup } from "react-bootstrap";
import { Link, useLocation } from "wouter";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { useLocalContext } from "../../../components/context/LocalContext";
import { usePhysicianContext } from "../../../components/context/physicianContext";
import { isRegisteredPatient } from "../../../components/domain/patient";
import { ENUM_SortPreferences } from "../../../components/domain/physician";
import { ApiErrorMessage } from "../../../components/ui/errors";
import { PlaceHolderCardBody } from "../../../components/ui/loading";
import { ControlledNavButton, PageNavigator, PagingListItemsMap } from "../../../components/ui/navigations";
import { Translate } from "../../../components/ui/text";

export const PatientListController = ({ pageNo = 0 }) => {
    const [searchValue, setSearchValue] = useState("");
    const [{ apiError, isInitialized, searchedResultFor, patientsList }, reducer] = usePhysicianContext();
    const [, navigate] = useLocation();
    const [{ i18n }] = useLocalContext();

    const handleOnChange = (e) => {
        const newSv = e.target.value;
        setSearchValue(newSv);
    }

    const handleKeyPress = (e) => {
        const key = e.which || e.keyCode;
        if (key === 13) {
            handleSearch(e, searchValue, reducer, navigate);
        }
    }

    if (isInitialized) {
        return (
            <>
                <ApiErrorMessage apiError={apiError} />
                <Form.Text className="text-muted"><Translate>physician.patients.searchHint</Translate></Form.Text>
                <InputGroup className="mb-3">
                    <FormControl type="text" placeholder={i18n.getEntry("physician.patients.searchPlaceHolder")} name="searchValue" value={searchValue} onChange={handleOnChange} onKeyPress={handleKeyPress} aria-label="Search" aria-describedby="basic-addon1" />
                    <Button type="button" variant="primary" id="button-addon1" onClick={(e) => handleSearch(e, searchValue, reducer, navigate)}><i className="bi bi-search" /></Button>
                </InputGroup>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <span><Translate>physician.patients.searchResult</Translate> - {searchedResultFor ? searchedResultFor : (<Translate>physician.patients.forAllPatients</Translate>)}</span>
                        <PatientListSortController />
                    </Card.Header>
                    <ListGroup variant="flush">
                        <PatientListView patients={patientsList} currentPage={pageNo} maxItems={6} />
                    </ListGroup>
                    <PageNavigator numberOfItems={patientsList?.length} currentPage={pageNo} maxItems={6} baseUrl="/private/physician/patients/" />
                </Card >
            </>
        );
    }

    return (<PatientListLoadingView />);
}

const handleSearch = (e, sv, dispatch, navigate) => {
    e.preventDefault();
    e.stopPropagation();

    if (sv) {
        return ApiPatientManagement.searchPatient(sv).then((result) => {
            if (Array.isArray(result)) {
                dispatch({ type: "searchResult", value: result, searchedResultFor: sv });
                return result;
            }

            //Only one record found
            navigate(`/private/physician/patients/${result.uid}/profile/`);
        }).catch((error) => {
            dispatch({ type: "apiError", value: { message: "No patients found with the provided health record number" } });
            return error;
        })
    }

    //List all patients for the authenticated physician
    return ApiPatientManagement.getAllPatientsOfAPhysician().then((result) => {
        dispatch({ type: "searchResult", value: result, searchedResultFor: "" });
        return result;
    }).catch((error) => {
        dispatch({ type: "apiError", value: error });
        return error;
    })
}

const dropDownToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a ref={ref} href="./#" onClick={(e) => { e.preventDefault(); onClick(e); }} title="Sort">
            {children}
        </a>
));

const PatientListSortController = () => {
    const [{ sortPreference }, dispatch] = usePhysicianContext();

    return (
        <Dropdown drop="start">
            <Dropdown.Toggle as={dropDownToggle} id="dropdown-basic" className="py-1">
                <i className="bi bi-filter" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as="button" active={sortPreference === ENUM_SortPreferences.assendingFullName} onClick={(e) => { e.preventDefault(); dispatch({ type: "sortPatients", sortPreference: ENUM_SortPreferences.assendingFullName }) }}>
                    <Translate>physician.patients.sort.fullName</Translate>&nbsp;<i className="bi bi-sort-alpha-up" />
                </Dropdown.Item>
                <Dropdown.Item as="button" active={sortPreference === ENUM_SortPreferences.desendingFullName} onClick={(e) => { e.preventDefault(); dispatch({ type: "sortPatients", sortPreference: ENUM_SortPreferences.desendingFullName }) }}>
                    <Translate>physician.patients.sort.fullName</Translate>&nbsp;<i className="bi bi-sort-alpha-down-alt" />
                </Dropdown.Item>
                <Dropdown.Item as="button" active={sortPreference === ENUM_SortPreferences.ascendingRegisteredDate} onClick={(e) => { e.preventDefault(); dispatch({ type: "sortPatients", sortPreference: ENUM_SortPreferences.ascendingRegisteredDate }) }}>
                    <Translate>physician.patients.sort.createdOn</Translate>&nbsp;<i className="bi bi-sort-numeric-up" />
                </Dropdown.Item>
                <Dropdown.Item as="button" active={sortPreference === ENUM_SortPreferences.desendingRegisteredDate} onClick={(e) => { e.preventDefault(); dispatch({ type: "sortPatients", sortPreference: ENUM_SortPreferences.desendingRegisteredDate }) }}>
                    <Translate>physician.patients.sort.createdOn</Translate>&nbsp;<i className="bi bi-sort-numeric-down-alt" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
/**
 * 
 * @param {Array} params.patients to display as list 
 * @param {Number} params.maxItems of patient to display in a list
 * @param {Number} params.currentPage Which page to display
 * @param {Function} params.onChangePage callback when the page changes
 * @returns 
 */
const PatientListView = ({ patients, maxItems = 15, currentPage = 0 }) => {

    const [, reducer] = usePhysicianContext();

    //Render found patients
    if (patients && patients.length > 0) {
        return (
            <PagingListItemsMap items={patients} maxItems={maxItems} currentPage={currentPage}>
                {
                    (p) => { return (<PatientItemListview patient={p} key={p.uid} />); }
                }
            </PagingListItemsMap>
        )
    }

    //No patients found
    return (
        <ListGroup.Item>
            <Alert variant="info">
                <p className="text-center"><Translate>physician.patients.noPatientsFound</Translate></p>
                <p className="text-center"><Translate>physician.patients.inviteNewPatient</Translate></p>
                <span className="text-center">
                    <Link href="/private/physician/invites/new"><ControlledNavButton handleClick={(e) => reducer({ type: "resetInivite" })} variant="outline-primary"><i className="bi bi-plus-square" /> <Translate>physician.invites.new</Translate></ControlledNavButton></Link>
                </span>
            </Alert>
        </ListGroup.Item>
    );
}

/**
 * Render a patient item list
 * @param {Object} patient is the patient item to display 
 * @returns the rendered patient item
 */
const PatientItemListview = ({ patient }) => {
    if (patient) {
        return (
            <Link href={"/private/physician/patients/" + patient.uid + "/profile/"}>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="d-flex justify-content-start"><i className={isRegisteredPatient(patient) ? "bi bi-person-check" : "bi bi-person-x"} />&nbsp;<strong>{patient.fullName}</strong></span>
                        <span className="text-muted"><Translate>physician.patients.healthRecord</Translate> #: {patient.healthRecordUid}</span>
                    </div>
                    <div><i className="bi bi-chevron-right" /></div>
                </ListGroup.Item>
            </Link>
        );
    }
}

/**
 * 
 * @returns an empty placehold to illustrate that we are loading the lsit of patients for the authenticated physician
 */
const PatientListLoadingView = () => {
    return (
        <Card>
            <Card.Header ><Translate>physician.patients.loadingPatients</Translate></Card.Header>
            <PlaceHolderCardBody />
        </Card>
    )
}