export class PreferencesMapObj {
    constructor(prefValues = []) {
        this.prefMap = new Map();
        this.addAll(prefValues);
    }

    addAll(preferences = []) {
        preferences?.forEach((e) => {
            this.prefMap.set(e.fullyQualifiedkey, e.value);
        })
    }

    /**
     * 
     * @param {String} key of the preference to get
     * @returns the Preference object corresponding ot the provided key if found, otherwise undefined is returned
     */
    getPreference(key) {
        return this.prefMap.get(key);
    }

    /**
     * 
     * @param {Object} pref object to store
     * @returns this PreferencesMapObj instance
     */
    setPreference(pref = {}) {
        if (pref?.fullyQualifiedkey) {
            this.prefMap.set(pref.fullyQualifiedkey, pref.value);
        }
        return this;
    }
}