import { isEmpty } from '../utils/utils';

export class InviteRequestObj {
    constructor({ uid = null, patientUid = null, inviteRequesterUid = null, healthRecordUid = "", firstName = "", lastName = "", contactMechanismType = null, phone = "", email = "", inviteSentDate = null, inviteToken = null, version = null, createdOn = null, lastModifiedOn = null }) {

        this.uid = uid;
        this.patientUid = patientUid;
        this.inviteRequesterUid = inviteRequesterUid;
        this.healthRecordUid = healthRecordUid;
        this.firstName = firstName;
        this.lastName = lastName;
        this.contactMechanismType = contactMechanismType;
        this.phone = phone;
        this.email = email;
        this.inviteSentDate = inviteSentDate;
        this.inviteToken = inviteToken;

        //Audit
        this.version = version;
        this.createdOn = createdOn;
        this.lastModifiedOn = lastModifiedOn;
    }

    /**
     * This method determines the communication type based on the current value of the object.  
     * If only one contact mechanism, the communication type will be set accordingly.  
     * If both contact mechanism are provided, this method will simply return the contactMechanismType attribute value.
     * @returns the communication type of this invite request object
     */
    configureCommunicationType() {
        if (isEmpty(this.email) || isEmpty(this.phone)) {

            //Only one value is present
            if (!isEmpty(this.email)) {
                this.contactMechanismType = "email"
                return "email";
            }

            this.contactMechanismType = "phone"
            return "phone";
        }

        //Set contact type if not already set
        if (isEmpty(this.contactMechanismType)) {
            this.contactMechanismType = "email";
        }

        return this.contactMechanismType;
    }

    /**
     * 
     * @param {Object} invite 
     * @param {String} ct is the contact mechanism to evaluate the invite against.  Either "email" or "phone" as value 
     * @returns true if the provided ct matches the invite request, otherwise false
     */
    isSentBy(ct = "") {
        return ct === this.contactMechanismType;
    }

    isSentByPhone() {
        return this.isSentBy("phone");
    }

    isSentByEmail() {
        return this.isSentBy("email");
    }
}

/**
 * 
 * @param {Array} invites raw json invites to convert as InviteRequestObj.
 * @returns a new Array of InviteRequestObj
 */
export function convertRawInvitesToInviteObjects(invites = []) {
    return invites.map((i) => new InviteRequestObj(i));
}