import moment from 'moment';
import { Button, Card, Dropdown, FloatingLabel, Form, ListGroup } from "react-bootstrap";
import { Link, useLocation } from "wouter";
import { ApiInviteManagement } from "../../../components/api/apiInviteManagement";
import { usePhysicianContext } from "../../../components/context/physicianContext";
import { ApiErrorMessage } from "../../../components/ui/errors";
import { ControlledNavButton, NavButton, PageNavigator, PagingListItemsMap } from "../../../components/ui/navigations";
import { FormDataHandling } from "../../../components/utils/formUtils";
import { emptyIfUndefined, getHrnValidator, isEmpty } from '../../../components/utils/utils';
import { Translate } from "../../../components/ui/text";
import { useLocalContext } from '../../../components/context/LocalContext';
import { useEffect } from 'react';

export const PendingInvitesListView = ({pageNo=0}) => {
    const [{ pendingInvites }, reducer] = usePhysicianContext();

    if (pendingInvites.length > 0) {
        return (
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div><Translate>physician.invites.pendingInvites</Translate></div>
                    <div><Link href="/private/physician/invites/new"><ControlledNavButton handleClick={(e) => reducer({ type: "resetInivite" })} variant="outline-primary"><i className="bi bi-plus-square" /> <Translate>physician.invites.new</Translate></ControlledNavButton></Link></div>
                </Card.Header>
                <ListGroup variant="flush">
                    <PendingInvitesList invites={pendingInvites} maxItems={6} currentPage={pageNo} dispatch={reducer} />
                </ListGroup>
                <PageNavigator numberOfItems={pendingInvites?.length} currentPage={pageNo} maxItems={6} baseUrl="/private/physician/invites/" />
            </Card >
        );
    }

    //Nothing to diaplay
    return (
        <Card>
            <Card.Header><Translate>physician.invites.noPendingInvites</Translate></Card.Header>
            <Card.Body className="text-center">
                <p><Translate>physician.invites.infoClickInvite</Translate></p>
                <Link href="/private/physician/invites/new"><ControlledNavButton handleClick={(e) => reducer({ type: "resetInivite" })} variant="outline-primary"><i className="bi bi-plus-square" /> <Translate>physician.invites.new</Translate></ControlledNavButton></Link>
            </Card.Body>
        </Card>
    );

}

const PendingInvitesList = ({ invites, maxItems = 7, currentPage=0 }) => {

    //Render found patients
    if (invites && invites.length > 0) {
        return (
            <PagingListItemsMap items={invites} maxItems={maxItems} currentPage={currentPage}>
                {
                    (i) => {
                        const sentMoment = moment(i.inviteSentDate);
                        const expiresMoment = moment(i.inviteSentDate).add(5, 'days');

                        return (
                            <Link key={i.uid} href={"/private/physician/invites/" + i.uid}>
                                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <span><strong>{i.lastName}, {i.firstName}</strong> <small>({i.healthRecordUid})</small></span>
                                        <div className="d-flex flex-wrap mt-1 text-muted">
                                            <span><small><Translate>physician.invite.sentOn</Translate>: {sentMoment.format("MMM Do, YYYY")}&nbsp;</small></span>
                                            <span><small>(<Translate>physician.invite.expires</Translate> {moment().to(expiresMoment)})</small></span>
                                        </div>
                                        <div className="d-flex flex-wrap mt-1">
                                            {i.email && <span className={i?.isSentByEmail() ? "text-primary me-2" : "text-muted me-2"}><i className="bi bi-at" />&nbsp;{i.email}</span>}
                                            {i.phone && <span className={i?.isSentByPhone() ? "text-primary me-2" : "text-muted me-2"}><i className="bi bi-phone" />&nbsp;{i.phone}</span>}
                                        </div>
                                    </div>
                                    <div><i className="bi bi-chevron-right" /></div>
                                </ListGroup.Item>
                            </Link>
                        )
                    }
                }
            </PagingListItemsMap>
        )
    }

    return null;
}

export const NewInviteFormController = () => {
    const [{ invite, apiError }, dispatch] = usePhysicianContext();
    const [, navigate] = useLocation();

    const handleOnChange = (e) => {
        FormDataHandling.handleOnChange(e, invite);
        dispatch({ type: "inviteStateChange", value: invite });
    }

    const isButtonDisabled = (isEmpty(invite.email) && isEmpty(invite.phone));
    return (
        <Form>
            <ApiErrorMessage apiError={apiError} />
            <Card>
                <Card.Header><Translate>physician.invite.newInivteTitle</Translate></Card.Header>
                <div className="m-2">
                    <InviteFormView invite={invite} onChange={handleOnChange} />
                </div>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <Link href="/private/physician/invites"><NavButton variant="outline-secondary"><Translate>physician.invite.cancel</Translate></NavButton></Link>
                    <SubmitInviteButton invite={invite} dispatch={dispatch} navigate={navigate} disabled={isButtonDisabled}><i className="bi bi-send" />&nbsp;<Translate>physician.invite.send</Translate></SubmitInviteButton>
                </Card.Footer>
            </Card>
        </Form>
    );
}

const SubmitInviteButton = ({ invite, children, dispatch, navigate, disabled = false, onSubmit = handleNewInviteSubmit }) => {
    if (isEmpty(invite.email) || isEmpty(invite.phone)) {
        return (
            <Button type="button" className="py-1" variant="outline-primary" disabled={disabled} onClick={(event) => onSubmit(invite, invite.configureCommunicationType(), event, dispatch, navigate)}>{children}</Button>
        );
    }

    return (
        <Dropdown className="py-1" variant="outline-primary" id="input-group-dropdown-1">
            <Dropdown.Toggle className="py-1" disabled={disabled}>{children}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item disabled={isEmpty(invite.email)} onClick={(event) => onSubmit(invite, "email", event, dispatch, navigate)}><Translate>physician.invite.sendByEmail</Translate></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item disabled={isEmpty(invite.phone)} onClick={(event) => onSubmit(invite, "phone", event, dispatch, navigate)}><Translate>physician.invite.sendBySms</Translate></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

const InviteFormView = ({ invite, onChange, isUpdateForm = false }) => {
    const [{ i18n }] = useLocalContext();
    const [{ preferences }] = usePhysicianContext();

    const hrnPreferences = getHrnValidator(preferences.getPreference("rwc.pwa.hrn.validator"));
    const isCheckDigitValid = hrnPreferences.validator(invite?.healthRecordUid);

    return (
        <>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.healthRecordUid" label={i18n.getEntry("generic.healthRecordNumber") + " #"} >
                    <Form.Control isInvalid={!isCheckDigitValid} isValid={isCheckDigitValid} type="text" pattern={hrnPreferences.pattern} inputMode={hrnPreferences.inputMode} name="healthRecordUid" placeholder="Enter health #" required={true} maxLength={hrnPreferences.maxLength} value={emptyIfUndefined(invite.healthRecordUid)} readOnly={isUpdateForm} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>physician.invite.invalid.hrn</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.firstName" label={i18n.getEntry("generic.firstName")} >
                    <Form.Control type="text" name="firstName" required isValid={invite.firstName} isInvalid={!invite.firstName} maxLength={50} autoComplete="given-name" placeholder="Enter first name" value={emptyIfUndefined(invite.firstName)} readOnly={isUpdateForm} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>physician.invite.invalid.valueRequired</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.lastName" label={i18n.getEntry("generic.lastName")}>
                    <Form.Control type="text" name="lastName" required isValid={invite.lastName} isInvalid={!invite.lastName} maxLength={50} autoComplete="family-name" placeholder="Enter last name" value={emptyIfUndefined(invite.lastName)} readOnly={isUpdateForm} onChange={onChange} />
                    <Form.Control.Feedback type="invalid"><Translate>physician.invite.invalid.valueRequired</Translate></Form.Control.Feedback>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.phone" label={i18n.getEntry("generic.phone")} >
                    <Form.Control type="tel" name="phone" autoComplete="tel-national" placeholder="Enter mobile #" maxLength={20} value={emptyIfUndefined(invite.phone)} onChange={onChange} />
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-2">
                <FloatingLabel controlId="fl.email" label={i18n.getEntry("generic.email")} >
                    <Form.Control type="email" name="email" autoComplete="email" placeholder="Enter email" maxLength={320} value={emptyIfUndefined(invite.email)} onChange={onChange} />
                </FloatingLabel>
            </Form.Group>
        </>
    )
}

/**
 * Handle the form submission event of a new visit
 * @param {Object} newInviteReq to dispatch once the invite is created
 * @param {String} ct is the contact mechanism to use for the invite, defaults to phone
 * @param {Object} event the onSubmit event object
 * @param {Function} disaptch the reducer function
 * @param {Function} navigate is the navigation function to use when the api is successful
 */
const handleNewInviteSubmit = (newInviteReq = null, ct = "phone", event, dispatch, navigate) => {

    event.preventDefault();

    if (newInviteReq) {
        newInviteReq.contactMechanismType = ct;
        ApiInviteManagement.invitePatient(newInviteReq).then((invite) => {
            if (invite) {
                dispatch({ type: "newInviteCreated", value: invite });
                navigate('/private/physician/invites/');
            }
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        });
    }
}

export const PatientInviteController = ({ inviteId }) => {
    const [ctx, dispatch] = usePhysicianContext();
    const [, navigate] = useLocation();

    useEffect(() => {
        dispatch({ type: "inviteStateChange", value: ctx.findPendingInviteByUid(inviteId) });
    }, [inviteId]);

    const { invite, apiError } = ctx;

    if (invite) {
        const sentMoment = moment(invite.inviteSentDate);

        const handleOnChange = (e) => {
            FormDataHandling.handleOnChange(e, invite);
            dispatch({ type: "inviteStateChange", value: invite });
        }

        return (
            <Form validated={true} noValidate>
                <ApiErrorMessage apiError={apiError} />
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <span><Translate>physician.invite</Translate></span>
                        <span><small><Translate>physician.invite.sentOn</Translate>: {sentMoment.format("MMM Do, YYYY [at] HH:mm")}</small></span>
                    </Card.Header>
                    <div className="m-2">
                        <InviteFormView invite={invite} onChange={handleOnChange} isUpdateForm={false} />
                    </div>
                    <Card.Footer className="d-flex justify-content-between align-items-center">
                        <Link href="/private/physician/invites"><NavButton variant="outline-secondary"><Translate>physician.invite.cancel</Translate></NavButton></Link>
                        <SubmitInviteButton dispatch={dispatch} invite={invite} navigate={navigate} onSubmit={handleUpdateAndResendInviteSubmit}><i className="bi bi-send" />&nbsp;<Translate>physician.invite.send</Translate></SubmitInviteButton>
                    </Card.Footer>
                </Card>
            </Form>
        )
    }

    return null;

}

/**
 * Handle the form submission event of an existing visit
 * @param {String} inviteReq to dispatch once the invite is created
 * @param {Object} event the onSubmit event object
 * @param {Function} disaptch the reducer function
 * @param {Function} navigate is the navigation function to use when the api is successful
 */
const handleUpdateAndResendInviteSubmit = (inviteReq = null, ct = "phone", event, dispatch, navigate) => {

    event.preventDefault();
    event.stopPropagation();

    inviteReq.contactMechanismType = ct;

    if (inviteReq && inviteReq.uid) {
        ApiInviteManagement.updateInvite(inviteReq).then((invite) => {
            if (invite) {
                dispatch({ type: "inviteUpdated", value: invite });
                navigate('/private/physician/invites/');
            }
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        });
    }
}