import React from "react";

export const PartyEmailView = ({party}) => {
    if (party?.emails.length > 0) {
        return (
            <a href={`mailto:${party.emails[0].value}`}><span className="text-nowrap"><i className="bi bi-at" />{party.emails[0].value}</span></a>
        );
    }
    return null;
}

export const PartyPhoneView = ({party}) => {
    if (party?.phones.length > 0) {
        return (
            <a href={`tel:${party.phones[0].value}`}><span className="text-nowrap"><i className="bi bi-phone" />{party.phones[0].value}</span></a>
        );
    }
    return null;
}

/**
 * Utility component to avoid displaying an 
 * @param {Array} cm to verfiy if it has contacts element in its array.
 * @returns the children elements if the provided cm as any elements in it
 */
 export const RenderIfContactMechanismPresents = ({cm, children}) => {
    if (cm?.length > 0) {
        return children;
    }
    return null;
}