
const ROLE_PATIENT = 'ROLE_PATIENT';
const ROLE_PHYSICIAN = 'ROLE_PHYSICIAN';

export class AuthenticatedUser {
    constructor(user) {
        this.firstName = user.given_name;
        this.lastName = user.family_name;
        this.email = user.email;
        this.isEmailVerified = user.email_verified;
        this.roles = user["http://rc2i.biz/roles"];
        this.personUid = user["http://rc2i.biz/personUid"];
        this.pictureUrl = user.picture;
        this.authSub = user.sub;
        this.lastUpdated = user.updated_at;
        this.locale = user.locale;
        this.nickname = user.nickname;
    }

    get isPatient() {
        return this.roles?.includes(ROLE_PATIENT) || false;
    }

    get isPhysician() {
        return this.roles?.includes(ROLE_PHYSICIAN) || false;
    }
}