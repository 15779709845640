import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React, { useState } from "react";
import { Button, Card, Collapse, Container, ListGroup, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "wouter";
import { APP_VERSION } from "../../configs/version";
import { LanguageSelectorController } from "./locale";
import { ControlledNavLink, isThisPathActive } from "./navigations";
import { Translate } from "./text";

export const PatientLayout = ({ children }) => {
    return (
        <div className="w-100">
            <TopPatientMenu />
            <Container>
                {children}
            </Container>
        </div>
    )
}

export const PhysicianLayout = ({ children }) => {
    return (
        <div className="w-100">
            <TopPhysicianMenu />
            <Container>
                {children}
            </Container>
        </div>
    )
}

export const PublicPageLayout = ({ children }) => {
    return (
        <div className="w-100">
            <TopPublicMenu />
            <Container>
                {children}
            </Container>
        </div>
    )
}

const OffCanvasMenu = ({ show, toggleMenu, children }) => {
    return (
        <Offcanvas show={show.menu} onHide={toggleMenu}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><Translate>layout.main-menu</Translate></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {children}
                <h6 className="mt-3"><Translate>layout.locale</Translate></h6>
                <ListGroup variant="flush">
                    <ListGroup.Item><div className="d-flex justify-content-between"><div><Translate>layout.language</Translate>:</div><div><LanguageSelectorController/></div></div></ListGroup.Item>
                </ListGroup>
                <h6 className="mt-3"><Translate>layout.information</Translate></h6>
                <ListGroup variant="flush">
                    <ListGroup.Item><div className="d-flex justify-content-between"><div><Translate>layout.version</Translate>:</div><div>{APP_VERSION}</div></div></ListGroup.Item>
                    <ListGroup.Item><div className="d-flex justify-content-between"><div><Translate>layout.copywright</Translate>:</div><div>&copy;2019 - {moment().format("YYYY")}</div></div></ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

const TopNavMenu = ({ toggleMenu, children }) => {
    return (
        <Navbar bg="secondary" expand={true} className="mb-3" variant="dark">
            <div className="d-flex justify-content-between sticky-top w-100">
                <a className="text-decoration-none" href="/"><span className="ms-2 text-white navbar-brand">RWC</span></a>
                {children}
                <Button title="Menu" variant='outline' className="text-white mx-2 mb-2 px-2 py-0" onClick={toggleMenu}><i className="bi bi-list" /></Button>
            </div>
        </Navbar>
    );
}

const TopPublicMenu = () => {
    const [show, setShow] = useState({ menu: false });
    const toggleMenu = (e) => { setShow({ ...show, menu: !show.menu }); }
    return (
        <>
            <TopNavMenu toggleMenu={toggleMenu} />
            <OffCanvasMenu show={show} toggleMenu={toggleMenu} />
        </>
    );
}

const TopPhysicianMenu = () => {
    const [show, setShow] = useState({ menu: false });
    const { logout, user } = useAuth0();

    const toggleMenu = (e) => { setShow({ ...show, menu: !show.menu }); }

    return (
        <>
            <TopNavMenu toggleMenu={toggleMenu}>
                <Nav>
                    <Nav.Item><Link href="/private/physician/patients/"><ControlledNavLink active={isThisPathActive("/private/physician/patients/:trail*")}><Translate>layout.patients</Translate></ControlledNavLink></Link></Nav.Item>
                    <Nav.Item><Link href="/private/physician/invites/"><ControlledNavLink active={isThisPathActive("/private/physician/invites/:trail*")}><Translate>layout.invites</Translate></ControlledNavLink></Link></Nav.Item>
                </Nav>
            </TopNavMenu>
            <OffCanvasMenu show={show} toggleMenu={toggleMenu}>
                <ListGroup variant="flush">
                    <h6><Translate>layout.general</Translate></h6>
                    <ListGroup.Item>
                        <a href="." onClick={(e) => { e.preventDefault(); logout({ returnTo: window.location.origin }) }}>
                            <div className="d-flex justify-content-between"><div><Translate>layout.logout</Translate>:</div><i className="bi bi-power" /></div>
                        </a>
                    </ListGroup.Item>
                    <ListGroup.Item><UserProfileView user={user} /></ListGroup.Item>
                </ListGroup>
            </OffCanvasMenu>
        </>
    );
}

const TopPatientMenu = () => {
    const [show, setShow] = useState({ menu: false });
    const { logout, user } = useAuth0();
    const toggleMenu = (e) => { setShow({ ...show, menu: !show.menu }); }

    return (
        <>
            <TopNavMenu toggleMenu={toggleMenu}>
                <Nav>
                    <Nav.Item><Link href="/private/patient/"><Nav.Link active={isThisPathActive("/private/patient/")}><Translate>layout.home</Translate></Nav.Link></Link></Nav.Item>
                    <Nav.Item><Link href="/private/patient/records/"><Nav.Link active={isThisPathActive("/private/patient/records/:trail*")}><Translate>layout.records</Translate></Nav.Link></Link></Nav.Item>
                    <Nav.Item><Link href="/private/patient/chat"><Nav.Link active={isThisPathActive("/private/patient/chat")}><Translate>layout.chat</Translate></Nav.Link></Link></Nav.Item>
                </Nav>
            </TopNavMenu>
            <OffCanvasMenu show={show} toggleMenu={toggleMenu}>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <a href="." onClick={(e) => { e.preventDefault(); logout({ returnTo: window.location.origin }) }}>
                            <div className="d-flex justify-content-between"><div>Logout:</div><i className="bi bi-power" /></div>
                        </a>
                    </ListGroup.Item>
                    <ListGroup.Item><UserProfileView user={user} /></ListGroup.Item>
                </ListGroup>
            </OffCanvasMenu>
        </>
    );
}

const UserProfileView = ({ user }) => {
    const [visible, toggelVisibility] = useState(false);
    return (
        <>
            <div className="d-flex justify-content-between" onClick={(e) => toggelVisibility(!visible)}>
                <div><Translate>layout.userProfile</Translate></div>
                <i className={visible ? "bi bi-chevron-down" : "bi bi-chevron-right"} />
            </div>
            <Collapse in={visible}>
                <div id="col-text">
                    <Card >
                        <div className="d-flex">
                            <img src={user.picture} alt="Avatar" className="me-2" />
                            <div>
                                <p>uid: {user["http://rc2i.biz/personUid"]}</p>
                                <p>email: {user.email}</p>
                                <p>Roles: {user["http://rc2i.biz/roles"]?.map((role) => role+", ")}</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </Collapse>
        </>
    );
}