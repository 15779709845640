import moment from "moment";
import { Alert, Button, Card, ListGroup, Tab, Tabs } from "react-bootstrap"
import { Link } from "wouter"
import { factoryDataUrl } from "../domain/visit";
import { toggleFullScreen } from "../utils/utils";
import { PagingListItemsMap } from "./navigations";
import { Translate } from "./text";
import { useLocalContext } from "../context/LocalContext";

export function PatientVisitListView({ visits, maxItems = 7, currentPage = 0, basePath = "" }) {

    if (visits && visits.length > 0) {
        return (
            <ListGroup variant="flush">
                <PagingListItemsMap items={visits} maxItems={maxItems} currentPage={currentPage}>
                    {
                        (v) => { return (<PatientVisitItemView key={v.uid} visit={v} basePath={basePath} />); }
                    }
                </PagingListItemsMap>
            </ListGroup>
        );
    }

    //Nothing to display
    return (
        <Card.Body>
            <p><Translate>view.patientVisit.noRecordsFound</Translate></p>
        </Card.Body>
    );
}

export function PatientVisitItemView({ visit, basePath }) {
    if (visit) {
        return (
            <Link href={basePath + "/" + visit.uid}>
                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                    <div>
                        <strong>{moment(visit.occuredOn).format("MMM Do, YYYY")}</strong> <small>({moment(visit.occuredOn).fromNow()})</small><br />
                        <span className="text-muted"><Translate>generic.width</Translate>:&nbsp;{visit.wound.xWidth}mm&nbsp;x&nbsp;<Translate>generic.height</Translate>:&nbsp;{visit.wound.yHeight}mm</span>
                    </div>
                    <div><i className="bi bi-chevron-right" /></div>
                </ListGroup.Item>
            </Link>
        );
    }

    //Nothin to return
    return null;
}

export function PatientVisitView({ visit }) {
    return (
        <>
            <div className="d-flex flex-wrap">
                <p><i className="bi bi-rulers" /> {visit.wound.xWidth}mm x {visit.wound.yHeight}mm</p>
            </div>
            <VisitImageCarousel images={visit.images} />
            <Alert variant='secondary'>
                <p><strong><Translate>generic.comments</Translate>:</strong></p>
                <PatientCommentsListView comments={visit.comments} />
            </Alert>
        </>
    );
}

export function PhysicianVisitView({ visit }) {
    const [{i18n}] = useLocalContext();

    return (
        <>
            <div className="d-flex flex-wrap">
                <p><i className="bi bi-rulers" /> {visit.wound.xWidth}mm x {visit.wound.yHeight}mm</p>
            </div>
            <VisitImageCarousel images={visit.images} />
            <Tabs defaultActiveKey="physician" id="uncontrolled-tab">
                <Tab eventKey="physician" title={i18n.getEntry("generic.practician")}>
                    <p><strong><Translate>view.patientVisit.doctorsNotes</Translate>:</strong></p>
                    <span>{visit.physicianNotes.comment}</span>
                </Tab>
                <Tab eventKey="patient" title={i18n.getEntry("generic.patient")}>
                    <PatientCommentsListView comments={visit.comments} />
                </Tab>
            </Tabs>
        </>
    );
}

function PatientCommentsListView({ comments }) {
    if (comments?.length > 0) {
        return comments.map((c, idx) => {
            return (
                <CommentItemView comment={c} key={idx} />
            )
        });
    }

    //Nothing to display
    return (
        <p><Translate>view.patientVisit.commentsNotProvided</Translate></p>
    );
}

function CommentItemView({ comment }) {
    if (comment) {
        return (
            <p>{comment.comment} <small className="text-muted">(<Translate>view.patientVisit.commentedOn</Translate>: {moment(comment.commentedOnDate).fromNow()})</small></p>
        );
    }

    //Nothing to display
    return null;
}

function VisitImageCarousel({ images }) {
    if (images?.length > 0) {
        return (
            <div className="d-flex flex-wrap justify-content-center">
                {images.map((img, idx) => { return (<VisitImageView image={img} key={idx} />); })}
            </div>
        );
    }

    //Nothing to display
    return (
        <p><Translate>view.patientVisit.imagesNotFound</Translate></p>
    );
}

function VisitImageView({ image }) {
    return (
        <Card style={{ width: '18rem' }} className="mb-2 me-2" >
            <img variant="top" alt="Captured wound" src={factoryDataUrl(image.imageMimeType, image.imageData)} onClick={(e) => toggleFullScreen(e.target)}/>
            <div className="mx-2 mt-2">
                <div className="d-flex justify-content-around align-items-center">
                    <p><small><strong><Translate>view.patientVisit.fileName</Translate>:</strong> {image.fileName}</small></p>
                    <Button variant="outline-primary" as="a" href={factoryDataUrl(image.imageMimeType, image.imageData)} download={image.fileName} className="ms-2 py-0"><i className="h2 py-0 bi bi-cloud-arrow-down" /></Button>
                </div>
            </div>
        </Card>
    )
}