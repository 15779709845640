import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { useAuthenticatedUserContext } from "../../../components/context/authenticatedUser";
import { usePatientContext } from "../../../components/context/patientContext";
import { ChatView } from "../../../components/ui/chat";
import { ApiErrorMessage, ApiSuccessMessage } from "../../../components/ui/errors";
import { Translate } from "../../../components/ui/text";

export const PatientChatController = () => {
    const [{apiError, patientUid, chatMessageSent, chat, chatMessage="", isSendingMessage=false}, dispatch] = usePatientContext();
    const [user] = useAuthenticatedUserContext();

    const handleSendMessage = (msg) => {
        dispatch({type: "sendingChatMessage"});
        ApiPatientManagement.postChatMessage(patientUid, msg).then((result) => {
            dispatch({ type: "messageSent", value: result });
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        })
    }

    const handleChatMessageChange = (msgVal) => {
        dispatch({type: "chatMessageStateChange", value: msgVal});
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <ApiSuccessMessage isShowing={chatMessageSent}><Translate>patient.chat.sentMessage</Translate></ApiSuccessMessage>
            <div className="d-flex justify-content-center"><span className="text-muted"><Translate>patient.chat.info</Translate></span></div>
            <ChatView chat={chat} message={chatMessage} onSend={handleSendMessage} senderPartyUid={user.personUid} onChange={handleChatMessageChange} isMessageBeingSent={isSendingMessage}/>
        </>
    );
}