
import React, { createContext, useContext, useReducer } from "react";
import { AuthenticatedUser } from "../domain/authenticatedUser";

const defaultReducer = (currentState, action) => {
    switch (action.type) {
        case "action":
            return {
                ...currentState,
            }
        default:
            console.error("Unknown reducer action", action);
            break;
    }
}

export const AuthenticatedUserContext = createContext({ uId: null, firstName: "", lastName: "", orgUid: null, roles: [] });

/**
 * Build the AuthenticatedUser object context for this app.  This is necessary to avoid spreading the IDP user object across our app in case we change IDP provider in the future
 * User must be authenticated before calling this context provider
 * @param {Object} user object from the IDP to build our internal user from 
 * @param {Object} children is the React children to render if the user is authenticated
 * @returns the provided children
 */
export const AuthenticatedUserContextProvider = ({ user, children }) => {
    const authUser = new AuthenticatedUser(user);

    return (
        <AuthenticatedUserContext.Provider value={useReducer(defaultReducer, authUser)}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
}

export const useAuthenticatedUserContext = () => useContext(AuthenticatedUserContext);