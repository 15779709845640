import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ApiPatientManagement } from "../api/apiPatientManagement";
import { Chat } from "../domain/chat";
import { initSimpleContacts } from "../domain/party";
import { PatientContextValue } from "../domain/patient";
import { VisitObj } from "../domain/visit";
import { PatientDownForMaintenance } from "../ui/errors";
import { PlaceHolderCard } from "../ui/loading";
import { decendingTimePredicate } from "../utils/utils";

const defaultReducer = (currentState, action) => {
    const newState = new PatientContextValue(currentState);

    console.debug("PatientContext-Reducer - action & current state", action, newState);

    switch (action.type) {
        case "initPatientContext":
            return new PatientContextValue({ patient: action.patient, visits: action.visits, chat: action.chat, patientUid: action.patientUid });
        case "apiError":
            newState.apiError = action.value;
            return newState;
        case "initError":
            newState.apiError = action.value;
            newState.initError = true;
            return newState;
        case "visitStateChanged":
            newState.visit = action.value;
            return newState;
        case "visitEdited":
            newState.addOrReplaceVisit(action.value);
            newState.hasVisitBeenUpdated = true;
            return newState;
        case "newVisitCreated":
            newState.visit = new VisitObj({}); //Reset the visit state
            newState.visits.unshift(action.value); //Add newly created visit at the beggining
            return newState;
        case "resetVisit":
            newState.visit = new VisitObj({});
            return newState;
        case "chatStateChange":
            newState.chat = new Chat(action.value);
            return newState;
        case "chatMessageStateChange":
            newState.chatMessage = action.value;
            return newState;
        case "sendingChatMessage":
            newState.isSendingMessage = true;
            return newState;
        case "messageSent":
            newState.chat.addMessage(action.value);
            newState.chatMessageSent = true;
            newState.isSendingMessage = false;
            return newState;
        case "patientStateChange":
            newState.patient = action.value;
            return newState;
        case "patientSaved":
            newState.oldPatient = { ...action.value };
            newState.oldPatient.email = { ...action.value.email };
            newState.oldPatient.phone = { ...action.value.phone };
            newState.patient = action.value;
            newState.patientIsSaved = true;
            return newState;
        case "cancelEditProfile":
            newState.patient = { ...newState.oldPatient };
            newState.patient.email = { ...newState.oldPatient.email };
            newState.patient.phone = { ...newState.oldPatient.phone };
            return newState;
        case "isLoading":
            newState.isLoading = true;
            return newState;
        case "isApiCallActive":
            newState.isApiCallActive = true;
            return newState;
        default:
            console.error("Unknown reducer action", action);
            return newState;
    }
}

export const PatientContext = createContext();

const initPatientContext = async (patientUid, dispatch) => {
    console.debug("initPatientContext - Initializing patient's context")

    dispatch({ type: "isLoading", value: patientUid });

    let error = null;
    const patient = await ApiPatientManagement.findPatientByUid(patientUid).catch((result) => {
        console.debug("initPatientContext - Patient error", result);
        error = result;
        return Promise.resolve(null);
    });

    //Error occured?, stop processing this uid
    if (error) {
        dispatch({ type: "initError", value: error });
        return;
    }

    initSimpleContacts(patient);

    const visits = await ApiPatientManagement.getPatientVisits(patientUid).catch((result) => {
        if (result.error && result.status === 403) {
            // Simply means no visits found for that patient
            return Promise.resolve([]);
        }

        return Promise.resolve(result);
    });
    const chat = await ApiPatientManagement.getPatientChat(patientUid);

    //Sort visits
    visits.sort((v1, v2) => { return decendingTimePredicate(v1.occuredOn, v2.occuredOn) });

    dispatch({ type: "initPatientContext", patient: patient, visits: visits, chat: new Chat(chat), patientUid: patientUid });

    return patient;
}

/**
 * Build the AuthenticatedUser object context for this app.  This is necessary to avoid spreading the IDP user object across our app in case we change IDP provider in the future
 * User must be authenticated before calling this context provider
 * @param {Object} user object from the IDP to build our internal user from 
 * @param {Object} children is the React children to render if the user is authenticated
 * @returns the provided children
 */
export const PatientContextProvider = ({ patientUid = "", children }) => {
    const ctx = useReducer(defaultReducer, new PatientContextValue({}));

    const [state, reducer] = ctx;

    useEffect(() => {
        initPatientContext(patientUid, reducer);
    }, [patientUid]); //State object cannot be part of the array by itself as it changes everytime we update its content.

    if (state.initError) {
        return (<PatientDownForMaintenance/>);
    }

    if (!state.isLoading) {
        return (
            <PatientContext.Provider value={ctx}>
                {children}
            </PatientContext.Provider>
        );
    }

    //Loading
    return (
        <PlaceHolderCard>Getting patient information</PlaceHolderCard>
    );
}

export const usePatientContext = () => useContext(PatientContext);