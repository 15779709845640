import { Form } from "react-bootstrap";
import { useLocalContext } from "../context/LocalContext";

export function LanguageSelectorController() {
    const [{ i18n }, reducer] = useLocalContext();

    return (
        <LanguageSelector lang={i18n.language} dispatch={reducer} />
    );
}

/**
 * 
 * @param {String} params.lang is the current selected language
 * @param {Function} params.dispatch is the callback reducer function for changing the language
 * @returns a render for selecting a language (EN or FR)
 */
export function LanguageSelector({ lang = "en", dispatch }) {
    const [{i18n}] = useLocalContext();

    return (
        <Form.Select aria-label={i18n.getEntry("locale.langSelection")} onChange={(e) => dispatch({ type: "changeLanguage", value: e.target.value })} value={lang}>
            <option>{i18n.getEntry("locale.selectOne")}</option>
            <option value="en">English</option>
            <option value="fr">Françcais</option>
        </Form.Select>
    );
}