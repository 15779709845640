import { ApiHelper } from './RestHelper';

class InviteManagementApi {

    constructor() {
        this.inviteRootUrl = "/invite-management/v1.0/invites";
        this.patientMgmntRootUrl = "/bff/patient-management/v1.0/patients";
        this.invitePatientUrl = "/bff/patient-management/v1.0/patients/registering";
        this.pendingInvitesUrl = "/invite-management/v1.0/invites/pending";
    }

    // /**
    //  * 
    //  * @param {string} inviteUid 
    //  */
    // getResendInviteUrl(inviteUid) {
    //     return `${this.inviteRootUrl}/${inviteUid}/resend`;
    // }

    getBffInviteUrl(inviteUid) {
        return `/bff${this.inviteRootUrl}/${inviteUid}`;
    }

    getValidateTokenUrl(token) {
        return "/invite-management/v1.0/invites/" + token;
    }

    getResendInviteUrl(hrn) {
        return `${this.patientMgmntRootUrl}/${hrn}/invites`;
    }

    invitePatient = async (inviteRequest) => {

        return ApiHelper.post(this.invitePatientUrl, inviteRequest).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    /**
     * Re-send the invite for the patient that has the provided HRN
     * @param {string} hrn health record number
     * @return {Object} invite request if the patient was found.
     */
    resendInvite = async (hrn) => {

        return ApiHelper.post(this.getResendInviteUrl(hrn), null).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    /**
     * Update an existing invite with this one
     * @param {object} invite to update
     * @returns {object} the udpated invite
     */
    updateInvite = async (invite) => {

        return ApiHelper.post(this.getBffInviteUrl(invite.uid), invite).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    getPendingInvites = async () => {
        return ApiHelper.get(this.pendingInvitesUrl).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    validateToken = async (token) => {
        return ApiHelper.getPublic(this.getValidateTokenUrl(token)).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }
}

export const ApiInviteManagement = new InviteManagementApi();