import React from "react";
import ChartistGraph from 'react-chartist';
import { Alert, Button, Card, Form, Nav } from "react-bootstrap";
import { Link, useLocation } from "wouter";
import { usePatientContext } from "../../../components/context/patientContext";
import { PlaceHolderCardBody, SpinnerWhenTrue } from "../../../components/ui/loading";
import { ControlledNavButton, isThisPathActive, NavButton, PageNavigator } from "../../../components/ui/navigations";
import { PatientCardHeaderview, PatientFormView } from "../../../components/ui/patient";
import { isRegisteredPatient } from "../../../components/domain/patient";
import { PatientVisitListView } from "../../../components/ui/patientVisit";
import { ApiErrorMessage } from "../../../components/ui/errors";
import { FormDataHandling } from "../../../components/utils/formUtils";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { initSimpleContacts } from "../../../components/domain/party";
import moment from "moment";
import { ApiInviteManagement } from "../../../components/api/apiInviteManagement";
import { usePhysicianContext } from "../../../components/context/physicianContext";
import { Translate } from "../../../components/ui/text";
import { getHrnValidator } from "../../../components/utils/utils";

export const PatientNavHeader = ({ patientUid }) => {
    const [{ patient }] = usePatientContext()

    const registeredClassName = isRegisteredPatient(patient) ? "h5 bi bi-person-check" : "h5 bi bi-person-x";

    const basePath = `/private/physician/patients/${patientUid}/`;
    return (
        <>
            <Alert variant="secondary"><i className={registeredClassName} />&nbsp;{patient.fullName} <small>({patient.healthRecordUid})</small></Alert>
            <Nav variant="tabs" defaultActiveKey={basePath + "profile/"} className="mb-2">
                <Nav.Item>
                    <Link href={basePath + "profile/"}><Nav.Link active={isThisPathActive(basePath + "profile/:trail*")}><Translate>physician.patient.nav.profile</Translate></Nav.Link></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link href={basePath + "records/"}><Nav.Link active={isThisPathActive(basePath + "records/:trail*")}><Translate>physician.patient.nav.records</Translate></Nav.Link></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link href={basePath + "chat"}><Nav.Link active={isThisPathActive(basePath + "chat")}><Translate>physician.patient.nav.chat</Translate></Nav.Link></Link>
                </Nav.Item>
            </Nav>
        </>
    );
}

export const PatientProfileView = ({ patientUid }) => {
    const [ctx, dispatch] = usePatientContext();
    const [, dispatchPhysicianContext] = usePhysicianContext();

    const resendInvite = () => {
        dispatch({ type: "isApiCallActive", value: true });

        // const inviteReq = new InviteRequestObj(ctx.patient);
        // inviteReq.configureCommunicationType();

        return ApiInviteManagement.resendInvite(ctx.patient.healthRecordUid).then((result) => {
            if (result?.version === 0) {
                //New invite so add it to the list
                dispatchPhysicianContext({ type: "newInviteCreated", value: result });
            }
            dispatch({ type: "dummy", value: false });
            return result;
        }).catch((error) => {
            dispatch({ type: "apiError", value: error });
            return error;
        })
    }

    if (ctx.isPatientLoaded()) {
        const patient = ctx.patient;
        return (
            <>
                <ApiErrorMessage apiError={ctx.apiError} />
                <Card className="mb-2">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <span><Translate>physician.patient.info</Translate></span>
                        <Link href={`/private/physician/patients/${patientUid}/profile/edit`}>
                            <NavButton variant="outline-primary"><i className="bi bi-pencil-square" />&nbsp;<Translate>physician.patient.edit</Translate></NavButton>
                        </Link>
                    </Card.Header>
                    <div className="m-2">
                        <PatientCardHeaderview patient={patient} />
                        <PatientRegistrationStatusView patient={patient} onResendInvite={resendInvite} isApiActive={ctx.isApiCallActive} />
                    </div>
                </Card>
                <PatientWoundProgressionChart records={ctx.visits} />
                <Alert variant="dark">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="me-2"><Translate>physician.patient.newrecord.note</Translate></div>
                        <Link href={"/private/physician/patients/" + patientUid + "/records/new"}>
                            <ControlledNavButton handleClick={(e) => dispatch({ type: "resetVisit" })} variant="outline-primary">
                                <span className="text-nowrap"><i className="bi bi-plus-square" />&nbsp;<Translate>physician.patient.newrecord</Translate></span>
                            </ControlledNavButton>
                        </Link>
                    </div>
                </Alert>
            </>
        );
    }

    if (ctx.apiError) {
        return (
            <ApiErrorMessage apiError={ctx.apiError}/>
        );
    }

    //Patient not loaded yet
    return (
        <Card>
            <Card.Header><Card.Header><Translate>physician.patient.laoding</Translate> #{ctx.patient.healthRecordUid}...</Card.Header></Card.Header>
            <Card.Body>
                <PlaceHolderCardBody />
            </Card.Body>
        </Card>
    );
}

/**
 * 
 * @param {Object} params.patient to verify if he is registered
 * @param {Function} params.onResendInvite callback function to re-send an invite to the provided patient
 * @param {Boolean} params.isApiActive to disable the re-send invite button while an API call is active
 * @returns the rendered status view if the provided patient is not registered, otherwise null
 */
const PatientRegistrationStatusView = ({ patient, onResendInvite, isApiActive = false }) => {
    if (!isRegisteredPatient(patient)) {
        return (
            <>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted me-1"><i className="bi bi-person-x" />&nbsp;: <Translate>physician.patient.registration.statusMessage</Translate></small>
                    <Button variant="outline-primary" className="p-1 text-nowrap" onClick={() => onResendInvite()} disabled={isApiActive}><SpinnerWhenTrue isLoading={isApiActive} loadingMessage="Sending..."><i className="bi bi-send" />&nbsp;<Translate>physician.patient.registration.reSend</Translate></SpinnerWhenTrue></Button>
                </div>
            </>
        )
    }

    //Nothing to display
    return null;
}

export const PhysicianEditPatientProfileController = ({ patientUid }) => {
    const [{preferences}] = usePhysicianContext();
    const [{ patient, apiError }, dispatch] = usePatientContext();
    const [, navigate] = useLocation();

    const hrnPreferences = getHrnValidator(preferences.getPreference("rwc.pwa.hrn.validator"));
    const isCheckDigitValid = hrnPreferences.validator(patient?.healthRecordUid);

    const handleSubmit = (e) => {
        e.preventDefault();

        //Init contact mechanism
        patient.contacts = [patient.email, patient.phone];

        ApiPatientManagement.updatePatient(patient).then((result) => {
            //re-apply the newly updated contacts
            initSimpleContacts(result);

            //Update the state
            dispatch({ type: "patientSaved", value: result });

            //Go back to the profile screen
            navigate(`/private/physician/patients/${result.uid}/profile/`);
        }).catch((err) => {
            dispatch({ type: "apiError", value: err });
        })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        dispatch({type: "cancelEditProfile"});

        //Go back to the profile screen
        navigate(`/private/physician/patients/${patient.uid}/profile/`);
    };

    const handleChange = (e) => {
        FormDataHandling.handleOnChange(e, patient);
        dispatch({ type: "patientStateChange", value: patient });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <Form onSubmit={handleSubmit}>
                <Card className="mb-3">
                    <Card.Header><span><Translate>physician.patient.profile.editTitle</Translate></span></Card.Header>
                    <PatientFormView patient={patient} onChange={handleChange} isUpdatedByPatient={false} isHealthRecordValid={isCheckDigitValid} hrnMaxLength={hrnPreferences.maxLength} hrnPattern={hrnPreferences.pattern} hrnInputMode={hrnPreferences.inputMode}/>
                    <div className="mx-2 mb-2 d-flex justify-content-between">
                        <Button variant="outline-secondary" type="button" onClick={handleCancel}><Translate>physician.patient.profile.cancel</Translate></Button>
                        <Button variant="outline-primary" type="submit"><Translate>physician.patient.profile.save</Translate></Button>
                    </div>
                </Card>
            </Form>
        </>

    )
}

export const PatientVisitListController = ({ patientId, pageNo = 0 }) => {
    const [{ visits }, dispatch] = usePatientContext();

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center" >
                <span ><Translate>physician.patient.records.title</Translate></span>
                <Link href={"/private/physician/patients/" + patientId + "/records/new"}>
                    <ControlledNavButton handleClick={(e) => dispatch({ type: "resetVisit" })} className="text-nowrap ms-1" variant="outline-primary">
                        <i className="bi bi-plus-square" /> <Translate>physician.patient.records.new</Translate>
                    </ControlledNavButton>
                </Link>
            </Card.Header>
            <PatientVisitListView visits={visits} maxItems={5} currentPage={pageNo} basePath={`/private/physician/patients/${patientId}/records`} />
            <PageNavigator numberOfItems={visits?.length} maxItems={5} currentPage={pageNo} baseUrl={`/private/physician/patients/${patientId}/records/`} />
        </Card>
    )
}

export const PatientWoundProgressionChart = ({ records }) => {

    if (records && records.length > 0) {
        return (
            <Card className="mb-2">
                <Card.Header><Translate>physician.patient.profile.chart.title</Translate></Card.Header>
                <div className="mt-2">
                    <ChartistGraph data={factoryChartDataFrom(records)} type="Bar" />
                </div>
                <div className="mx-2 text-muted text-center">
                    <small><i className="bi bi-info-circle" />&nbsp;<Translate>physician.patient.profile.chart.info</Translate></small>
                </div>
            </Card>
        );
    }

    //Nothing to render
    return null;
}

/**
 * 
 * @param {Array} records of a patients to represent as a chart
 * @returns a structured object to be used in a Chartist as data
 */
function factoryChartDataFrom(records) {
    const chartData = {
        labels: [],
        series: [[]]
    }

    let idx = 1;
    for (let v of records) {
        if (idx < 6) {
            chartData.labels.unshift(moment(v.occuredOn).format("MMM-Do"));
            chartData.series[0].unshift(v?.wound.area ? v.wound.area : 0);
            idx++;
            continue;
        }
        break;
    }

    return chartData;
}