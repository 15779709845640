import { useAuth0 } from "@auth0/auth0-react";
import React, {useEffect} from "react";
import { Button, Card } from "react-bootstrap";
import { useLocation } from "wouter";
import { AuthenticatedUser } from "../components/domain/authenticatedUser";
import { PublicPageLayout } from "../components/ui/layout";
import { Translate } from "../components/ui/text";

export const Home = ({ id }) => {
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [, navigate] = useLocation();

    useEffect(() => {
        //If authenticated, redirect to their respective home page.
        if (isAuthenticated) {
            const authUser = new AuthenticatedUser(user);
            navigate(authUser.isPatient ? "/private/patient/" : "/private/physician/patients/");
            return null;
        }
    }, [id, isAuthenticated]);

    //Not authenticated
    return (
        <LoginMessage loginWithRedirect={loginWithRedirect} />
    );
}

const LoginMessage = ({ loginWithRedirect }) => {
    return (
        <PublicPageLayout>
            <Card border="light">
                <Card.Img className="img-fluid bg-secondary w-100" variant="top" src="/assets/remote-health-care.png" />
                <Card.Body className="d-flex flex-column justify-content-center text-center">
                    <Card.Title><Translate>home.welcome-rwc</Translate>&nbsp;(RWC&trade;)</Card.Title>
                    <p><Translate>home.login-message</Translate></p>
                    <div>
                        <Button variant="outline-primary" onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}><i className="bi bi-box-arrow-in-right" /> <Translate>generic.login</Translate></Button>
                    </div>
                </Card.Body>
            </Card>
        </PublicPageLayout>
    );
}