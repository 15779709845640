export function factoryComment({ partyUid = null, fullName = null, commentedOn = new Date(), comment = null }) {
    return {
        "partyUid": partyUid,
        "partyFullName": fullName,
        "commentedOnDate": commentedOn,
        "comment": comment
    };
}

/**
 * Builds a base64 data url as a String
 * @param {String} type of image
 * @param {String} data in base64 format
 * @returns a formated data url
 */
export function factoryDataUrl(type, data) {
    return `data:${type};base64,${data}`;
}

/**
 * 
 * @param {String} params.name of the image file
 * @param {String} params.comment of the image
 * @param {String} params.image image data url of the image
 * @param {String} params.type of the image
 * @returns an Image object that can be added into a Wound object
 */
export function factoryImage({ name = null, comment = factoryComment({}), image = null, type = null }) {
    const endIdx = image.indexOf(';base64,');

    //Extract the image data
    const imgData = image && image.startsWith("data:") ? image.slice(endIdx + 8) : image;

    //Extract the image type from the DAta url, when not provided as param
    const mimeType = !type && image && image.image.startsWith("data:") ? image.slice(5, endIdx) : type;

    return {
        "fileName": name,
        "physicianComment": comment,
        "imageData": imgData,
        "imageMimeType": mimeType
    };
}

export function factoryWound({ x = 0, y = 0, z = 0 }) {
    return {
        xWidth: x,
        yHeight: y,
        zDepth: z
    }
}

export class VisitObj {
    constructor({ version = null, createdOn = null, lastModifiedOn = null, lastUpdatedByPartyUid = null, physicianUid = null, patientUid = "", occuredOn = new Date(), images = [],
        comments = [factoryComment({})], physicianNotes = factoryComment({}), wound = factoryWound({}), uid = null }) {
        this.version = version;
        this.createdOn = createdOn;
        this.lastModifiedOn = lastModifiedOn;
        this.physicianUid = physicianUid;
        this.patientUid = patientUid;
        this.occuredOn = occuredOn;
        this.images = images;
        this.comments = comments;
        this.physicianNotes = physicianNotes;
        this.wound = wound;
        this.uid = uid;
        this.lastUpdatedByPartyUid = lastUpdatedByPartyUid
    }

    hasImages() {
        return this.images && this.images.length && this.images.length > 0;
    }

    resetImages() {
        this.images = [];
    }
    
    /**
     * Add an image to this visit by passing a URL data
     * @param {String} urlData to add as an image
     */
    replaceImagesWith(files) {
        if (this.images.length === 3) {
            this.images = files;
        } else {
            this.images = this.images.concat(files);
        }

        return this.images.splice(3);
    }
}