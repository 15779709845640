import React from "react";
import { Route, Switch } from "wouter";
import { PatientContextProvider } from "../../../components/context/patientContext";
import { PhysicianContextProvider } from "../../../components/context/physicianContext";
import { PageNotFound } from "../../../components/ui/errors";
import { PhysicianLayout } from "../../../components/ui/layout";
import { getSearchParam, parseNumber } from "../../../components/utils/utils";
import { PhysicianChatController } from "./chat";
import { NewInviteFormController, PatientInviteController, PendingInvitesListView } from "./invite";
import { PatientNavHeader, PatientProfileView, PatientVisitListController, PhysicianEditPatientProfileController } from "./patient";
import { PatientListController } from "./patients";
import { PhysicianNewVisitController, PhysicianEditPatientVisitController, PhysicianVisitViewController } from "./visit";

/**
 * Main page for the Physician persona
 * @returns 
 */
export const PhysicianRoutesPages = () => {

    return (
        <PhysicianContextProvider>
            <PhysicianLayout>
                <Switch>
                    <Route path="/private/physician/patients/:patientId/:trailing*">{({ patientId }) => (
                        <PatientContextProvider patientUid={patientId}>
                            <PatientNavHeader patientUid={patientId} />
                            <Switch>
                                <Route path="/private/physician/patients/:patientId/profile/edit">{({ patientId }) => <PhysicianEditPatientProfileController patientUid={patientId} />}</Route>
                                <Route path="/private/physician/patients/:patientId/profile/">{({ patientId }) => <PatientProfileView patientUid={patientId} />}</Route>
                                <Route path="/private/physician/patients/:patientId/chat">{({ patientId }) => <PhysicianChatController patientUid={patientId} />}</Route>
                                <Route path="/private/physician/patients/:patientId/records/">{({ patientId }) => <PatientVisitListController patientId={patientId} pageNo={parseNumber(getSearchParam("pageNo"))}/>}</Route>
                                <Route path="/private/physician/patients/:patientId/records/new">{({ patientId }) => <PhysicianNewVisitController patientId={patientId} />}</Route>
                                <Route path="/private/physician/patients/:patientId/records/:visitId">{({ patientId, visitId }) => <PhysicianVisitViewController patientId={patientId} visitId={visitId} />}</Route>
                                <Route path="/private/physician/patients/:patientId/records/:visitId/edit">{({ patientId, visitId }) => <PhysicianEditPatientVisitController patientId={patientId} visitId={visitId} />}</Route>
                                <Route><PageNotFound /></Route>
                            </Switch>
                        </PatientContextProvider>
                    )}
                    </Route>
                    <Route path="/private/physician/invites/:trailing*">
                        <Switch>
                            <Route path="/private/physician/invites/new"><NewInviteFormController /></Route>
                            <Route path="/private/physician/invites/:inviteId">{({ inviteId }) => { return (<PatientInviteController inviteId={inviteId} />); }}</Route>
                            <Route>{() => <PendingInvitesListView pageNo={parseNumber(getSearchParam("pageNo"))}/>}</Route>
                        </Switch>
                    </Route>
                    <Route>{() => <PatientListController pageNo={parseNumber(getSearchParam("pageNo"))} />}</Route>
                </Switch>
            </PhysicianLayout>
        </PhysicianContextProvider>
    );
}