export class Chat {
    constructor({ participants = [], messages =[], uid = null, patientUid = null }) {
        this.participants = participants;
        this.messages = messages ? messages : [];
        this.uid = uid;
        this.patientUid = patientUid;
    }

    addParticipant(partyUid = null) {
        if (partyUid) {
            return this.participants.push(partyUid)
        }
        return this.participants.length;
    }

    addMessage(message = null) {
        if (message) {
            return this.messages.push(message);
        }
        return this.messages.length;
    }

    /**
     * 
     * @returns true when this chat has messages, otherwise false
     */
    hasMessages() {
        return this.messages && this.messages.length > 0;
    }
}

/**
 * Returns an initialized message with the provided values
 * @param {*} partyUid 
 * @param {*} name 
 * @param {*} date 
 * @param {*} comment 
 */
export function factoryMessage({ partyUid = null, name = null, date = new Date(), message = "" }) {
    return {
        "partyUid": partyUid,
        "partyFullName": name,
        "commentedOnDate": date,
        "comment": message
    };
}