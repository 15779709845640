import React from "react";
import { Route, Switch } from "wouter";
import { useAuthenticatedUserContext} from "../../../components/context/authenticatedUser";
import { PatientContextProvider } from "../../../components/context/patientContext";
import { PageNotFound } from "../../../components/ui/errors";
import { PatientLayout } from "../../../components/ui/layout";
import { getSearchParam, parseNumber } from "../../../components/utils/utils";
import { PatientChatController } from "./chat";
import { PatientEditProfileController, PatientHome } from "./patient";
import { PatientNewVisitController, PatientEditVisitController, PatientVisitsListController, PatientVisitViewController } from "./visit";

export const PatientRoutesPages = () => {
    const [authUser] = useAuthenticatedUserContext();

    return (
        <PatientContextProvider patientUid={authUser.personUid}>
            <PatientLayout>
                <Switch>
                    <Route path="/private/patient/"><PatientHome /></Route>
                    <Route path="/private/patient/edit"><PatientEditProfileController /></Route>
                    <Route path="/private/patient/records/:trailing*">
                        <Switch>
                            <Route path="/private/patient/records/">
                                {() => <PatientVisitsListController pageNo={parseNumber(getSearchParam("pageNo"))}/>}
                            </Route>
                            <Route path="/private/patient/records/new"><PatientNewVisitController /></Route>
                            <Route path="/private/patient/records/:recordId">{({ recordId }) => { return (<PatientVisitViewController visitId={recordId} />) }}</Route>
                            <Route path="/private/patient/records/:recordId/edit">{({ recordId }) => { return (<PatientEditVisitController visitId={recordId} />) }}</Route>
                            <Route><PageNotFound /></Route>
                        </Switch>
                    </Route>
                    <Route path="/private/patient/chat"><PatientChatController /></Route>
                    <Route path="/private/patient/profile"><PageNotFound /></Route>
                    <Route><PageNotFound /></Route>
                </Switch>
            </PatientLayout>
        </PatientContextProvider>
    );
}