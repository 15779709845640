/**
 * 
 * @param {String} cn the class name to append active to  
 * @param {String} href the matching href to base our decision if active or not
 * @returns the supplied class name with the additional "active" class if matching the provided href
 */
export function addActiveClassIfUrlStartsWith(cn, href) {
    return cn + (window.location.pathname.startsWith(href) ? " active text-white" : " text-white-50");
}

/**
 * 
 * @param {String} cn the class name to append active to  
 * @param {String} href the matching href to base our decision if active or not
 * @returns the supplied class name with the additional "active" class if matching the provided href
 */
export function addActiveClassIfMatchesHref(cn, href) {
    return cn + (window.location.pathname === href ? " active text-white" : " text-white-50");
}

/**
 * Function utility to wrap another funciton that requires only the last call to be performed.  The last called is dertermined by a timeout.
 * @param {function} fn is the function to call once the timeout is triggered
 * @param {Array} ars an array of arguments to pass to the function
 * @param {int} timeout to trigger the function, in milli-seconds.
 */
export function debounce(fn, args = [], timeout, timerId) {
    console.debug("Timer id:", timerId);

    //Clear the timer if any. That way any pending timer won't trigger the function
    clearTimeout(timerId);

    timerId = setTimeout(() => {
        console.debug("Debounce: Calling function...");
        fn.apply(this, args);
    }, timeout);

    return timerId;
}

/**
 * 
 * @param {Number} t1 is the time to compate to 
 * @param {Number} t2 is the time to compate with
 * @returns -1 if t1 is greater than t2, othwerise +1
 */
export function decendingTimePredicate(t1, t2) {
    if (t1 === t2) {
        return 0;
    }

    return t1 > t2 ? -1 : 1
}

/**
 * 
 * @param {any} value 
 * @returns an empty string if the provided value is null or undefined, otherwise it returns the provide value unchanged
 */
export function emptyIfUndefined(value) {
    return value ? value : "";
}

export function isEmpty(value) {
    return (value == null || value.length === 0);
}

/**
 * 
 * @param {String} province to find the validator for
 * @returns  the found validator if any, otherwise a dummy validator that always return true when the value is not empty;
 */
export function getHrnValidator(province = "") {
    switch (province) {
        case "ON":
            return {
                validator: validateOntarioHealthNo,
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]{9,10}"
            };
        case "QC":
            return {
                validator: dummyHrnValidator,
                maxLength: 12,
                inputMode: "text",
                pattern: "[A-Za-z]{4}[0-9]{4}[0-9]{4}"
            };
        default:
            return {
                validator: dummyHrnValidator,
                maxLength: 20,
                inputMode: "text",
                pattern: "[A-Za-z0-9]{9,20}"
            };
    }
}

export function validateOntarioHealthNo(healthNo) {
    if (healthNo?.length === 10) {
        //console.log("Validate:", calculateOntarioHealthNoCheckDigit(healthNo.substring(0,9)).toString(), healthNo.charAt(9));
        return calculateOntarioHealthNoCheckDigit(healthNo.substring(0, 9)).toString() === healthNo.charAt(9);
    }
    return false;
}

/**
 * Dummy validator, that simply verify that a value is provided.
 * @param {String} healthNo 
 * @returns true if the value is not empty, otherwise false
 */
export function dummyHrnValidator(healthNo) {
    return isEmpty(healthNo) ? false : true;
}

/**
 * Calculate the check digit of the Ontario health record numbers based on their specification using modulo 10
 * @param {String} healthNo to calculate the check digit.  Must be of 9 characters in lenght.
 * @returns the calculated check digit based on the provided health record number
 */
export function calculateOntarioHealthNoCheckDigit(healthNo) {
    const doubleDigits = [0, 2, 4, 6, 8];
    const numAsString = healthNo.toString();
    let numAcross = 0

    for (let i = 0; i < numAsString.length; i++) {
        if (doubleDigits.includes(i)) {
            let doubleD = (Number.parseInt(numAsString[i]) * 2)
            if (doubleD > 9) {
                doubleD = ((doubleD % 10) + 1);
            }
            numAcross = numAcross + doubleD;
        } else {
            numAcross = numAcross + Number.parseInt(numAsString[i]);
        }
    }
    const checkDigit = (10 - (numAcross % 10)) % 10;

    //console.log("Validated Num", healthNo, checkDigit, numAcross);

    return checkDigit;
}

/**
 * 
 * @param {Array} array Containing the element to replace
 * @param {Object} newElement to replace the old one 
 * @param {function} predicate to use to find the element to replace
 */
export function replaceElementWith(array, newElement) {

    const idx = array.findIndex((e) => e.uid === newElement.uid);

    if (idx > -1) {
        array.splice(idx, 1, newElement);
    }
}

/**
 * 
 * @param {String} param value to extract from the URL
 * @returns 
 */
export function getSearchParam(param) {
    return new URLSearchParams(window.location.search).get(param);
}

/**
 * 
 * @param {String} sVal to parse as number
 * @returns the parsed number or 0 if the string is not a number
 */
export function parseNumber(sVal) {
    const nVal = Number.parseInt(sVal);

    return Number.isNaN(nVal) ? 0 : nVal;
}

export function dummyFunction() {

}

export function toggleFullScreen(refElement) {

    // This is to avoid issue with specific browser
    //                             Standard API                             Safari
    const requestFullScreen = refElement.requestFullscreen || refElement.webkitRequestFullscreen

    //Verify if we can request fullscreen on that element
    if (requestFullScreen) {
        requestFullScreen.call(refElement);
    }
}

/**
 * 
 * @returns the first two characters of the prefered languag of the browser
 */
export function getBrowserPreferedLanguage() {
    return navigator.language.slice(0, 2);
}