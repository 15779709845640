export function factoryEmail(email = null) {
    return {
        "_class": "Email",
        "state": null,
        "role": "home",
        "type": "Email",
        "email": email
    }
}

/**
 * Retuns the first email found from the provided party.
 * @param {Object} party object to extract email from
 */
export function factoryEmailFromServer(party) {
    if (party && party.emails && party.emails.length > 0) {
        return party.emails[0];
    }
    return {};
}

/**
 * Initialize any missing contact mechanism for the provided party.
 * @param {object} party object to initialize cm 
 * @returns {object} the initialized party object
 */
export function initMissingPartyContactMechanism(party) {
    if (party && party.emails && party.emails.length === 0) {
        party.contacts.push(factoryEmail());
    }
    if (party && party.phones && party.phones.length === 0) {
        party.contacts.push(factoryPhone());
    }
    return party;
}

/**
 * Retuns the first phone found from the provided party.
 * @param {Object} party object to extract phone from
 */
export function factoryPhoneFromServer(party) {
    if (party && party.phones && party.phones.length > 0) {
        return party.phones[0];
    }
    return {};
}

export function factoryPhone(phone = null) {
    return {
        "_class": "Phone",
        "state": null,
        "role": "home",
        "phoneType": "mobile",
        "countryCode": 1,
        "extention": -1,
        "type": "Phone",
        "phone": phone
    }
}

export function factoryAddress() {
    return {
        "_class": "Address",
        "state": null,
        "role": null,
        "uuId": null,
        "civicNo": null,
        "streetName": null,
        "cityName": null,
        "provinceCode": null,
        "countryCode": null,
        "postalCode": null,
        "type": "Address",
    }
}

export function factoryServerPatient({ contacts = [], firstName = null, lastName = null, gender = "male", dateOfBirth = null, healthRecordUid = null, phone = null, email = null } = {}) {

    return {
        "contacts": [factoryEmail(email), factoryPhone(phone)],
        "firstName": firstName,
        "lastName": lastName,
        "gender": gender,
        "dateOfBirth": dateOfBirth,
        "healthRecordUid": healthRecordUid,
        "phone": phone,
        "email": email,
    }
}

export function isContactMechanismEmail(contact) {
    return contact && contact.type === "Email"
}

export function isContactMechanismPhone(contact) {
    return contact && contact.type === "Phone"
}

export function getFirstPhoneIndex(contacts = []) {
    return contacts.findIndex((c) => isContactMechanismPhone(c));
}

export function getFirstEmailIndex(contacts = []) {
    return contacts.findIndex((c) => isContactMechanismEmail(c));
}

export function initSimpleContacts(patient) {
    if (patient) {
        //Init email
        let idx = getFirstEmailIndex(patient.contacts);
        patient.email =  idx > -1 ? patient.contacts[idx] : factoryEmail("");

        //Init phone
        idx = getFirstPhoneIndex(patient.contacts);
        patient.phone =  idx > -1 ? patient.contacts[idx] : factoryPhone("");
    }
}

export function getFirstEmail(contacts = []) {
    const idx = getFirstEmailIndex(contacts)
    return idx > -1 ? contacts[idx] : null;
}

export function getFirstPhone(contacts =[]) {
    const idx = getFirstPhoneIndex(contacts);
    return idx > -1 ? contacts[idx] : null;
}