import { useAuth0 } from "@auth0/auth0-react";
import { Card, Spinner } from "react-bootstrap";
import { ApiHelper } from "../api/RestHelper";
import { PublicPageLayout } from "../ui/layout";
import { Translate } from "../ui/text";

const ROLE_PATIENT = 'ROLE_PATIENT';
const ROLE_RWC = 'ROLE_RWC';
const ROLE_PHYSICIAN = 'ROLE_PHYSICIAN';

export function isRWC(user) {
    return user && user["http://rc2i.biz/roles"] && user["http://rc2i.biz/roles"].includes(ROLE_RWC);
}

/**
 * @param {Object} user is the currently authenticated user
 * @returns true when the provided user is a patient, otherwise false
 */
export function isPatient(user) {
    return user && user["http://rc2i.biz/roles"] && user["http://rc2i.biz/roles"].includes(ROLE_PATIENT);
}

/**
 * @param {Object} user is the currently authenticated user
 * @returns true when the provided user is a physician, otherwise false
 */
export function isPhysician(user) {
    return user && user["http://rc2i.biz/roles"] && user["http://rc2i.biz/roles"].includes(ROLE_PHYSICIAN);
}

/**
 * 
 * @param {Object} user is the authenticated user 
 * @returns the RWC personUid associated to that authenticated user
 */
export function getUserPartyUid(user) {
    return user && user["http://rc2i.biz/personUid"];
}

export function InitRestHelper({ children }) {

    //Initialize the ApiHelper with Auth0 required methods
    const auth0 = useAuth0();
    ApiHelper.auth0Client = auth0;
    
    if (auth0.isLoading) {
        return (
            <SecurityContextLoading/>
        )
    }

    //Auth0 initialized
    return children;
}

function SecurityContextLoading() {
    return (
        <PublicPageLayout>
        <Card border="light">
            <Card.Img className="img-fluid bg-secondary w-100" variant="top" src="/assets/remote-health-care.png" />
            <Card.Body className="d-flex flex-column justify-content-center text-center">
                <Card.Title><Translate>security.init.title</Translate> (RWC&trade;)</Card.Title>
                <p><Translate>security.init.loadingMessage</Translate></p>
                <div><Spinner animation="grow" /></div>
            </Card.Body>
        </Card>
    </PublicPageLayout>
    )
}