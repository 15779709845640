import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import { Link, Route, Switch } from "wouter";
import { AuthenticatedUserContextProvider, useAuthenticatedUserContext } from "../components/context/authenticatedUser";
import { PublicPageLayout } from "../components/ui/layout";
import { PageNotFound } from "../components/ui/errors";
import { PatientRoutesPages } from "./private/patient/patientRoutes";
import { PhysicianRoutesPages } from "./private/physician/physicianRoutes";
import { RegisteredController, TokenValidationController } from "./private/patient/registration";
import { Translate } from "../components/ui/text";

export const PublicRoutes = (props) => {
    return (
        <PublicPageLayout>
            <Switch>
                <Route path="/tokens/:tokenId">{({ tokenId }) => <TokenValidationController tokenId={tokenId} />}</Route>
                <Route path="/registered/:tokenId">{({ tokenId }) => <RegisteredController tokenId={tokenId} />}</Route>
                <Route><PageNotFound /></Route>
            </Switch>
        </PublicPageLayout>
    );
}

export const PrivateRoutes = ({ path }) => {
    return (
        <AuthenticatedRoutes>
            <Switch>
                <Route path="/private/physician/:trailingPath*">{AuthenticatedPhysicianRoutes}</Route>
                <Route path="/private/patient/:trailingPath*">{AuthenticatedPatientRoutes}</Route>
                <Route><PageNotFound /></Route>
            </Switch>
        </AuthenticatedRoutes>
    )
}

const AuthenticatedPhysicianRoutes = () => {
    return (
        <PhysicianAuthenticated>
            <PhysicianRoutesPages />
        </PhysicianAuthenticated>
    )
}

const AuthenticatedPatientRoutes = () => {
    return (
        <PatientAuthenticated>
            <PatientRoutesPages />
        </PatientAuthenticated>
    )
}

const AuthenticatedRoutes = ({ children }) => {
    const { isAuthenticated, loginWithRedirect, user } = useAuth0();
    if (isAuthenticated) {
        return (
            <AuthenticatedUserContextProvider user={user}>
                {children}
            </AuthenticatedUserContextProvider>
        );
    }

    //Otherwise
    return (
        <PublicPageLayout>
            <Alert variant="warning">
                <Alert.Heading><i className="bi bi-lock" /> <Translate>error.http.401.title</Translate></Alert.Heading>
                <hr />
                <p><Translate>error.http.401.message</Translate></p>
                <div className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}><i className="bi bi-box-arrow-in-right" /> <Translate>generic.login</Translate></Button>
                </div>
            </Alert>
        </PublicPageLayout>
    );
}

const PatientAuthenticated = ({ children }) => {
    const [user] = useAuthenticatedUserContext();
    if (user && user.isPatient) {
        return children;
    }

    //Otherwise
    return (
        <PublicPageLayout>
            <Alert variant="warning">
                <Alert.Heading><i className="bi bi-lock" /> <Translate>error.http.403.title</Translate></Alert.Heading>
                <p><Translate>error.http.403.message</Translate></p>
                <div className="d-flex justify-content-center">
                    <Link href="/"><Button variant="outline-primary"><Translate>generic.backHome</Translate></Button></Link>
                </div>
            </Alert>
        </PublicPageLayout>
    );
}

const PhysicianAuthenticated = ({ children }) => {
    const [user] = useAuthenticatedUserContext();
    if (user && user.isPhysician) {
        return children;
    }

    //Otherwise
    return (
        <PublicPageLayout>
            <Alert variant="warning">
                <Alert.Heading><i className="bi bi-lock" /> <Translate>error.http.403.title</Translate></Alert.Heading>
                <p><Translate>error.http.403.message</Translate></p>
                <div className="d-flex justify-content-center">
                    <Link href="/"><Button variant="outline-primary"><Translate>generic.backHome</Translate></Button></Link>
                </div>
            </Alert>
        </PublicPageLayout>
    );
}