import { ApiHelper } from './RestHelper';
import { factoryMessage } from '../domain/chat';
import { factoryComment } from '../domain/visit';

class PatientManagementApi {

    constructor() {
        this.apiBaseRoute = "/party-management/v1.0";
        this.apiChatBaseRoute = "/chat-management/v1.0"

        this.patientsRoute = "/patients";
        this.patientsUrl = this.apiBaseRoute + this.patientsRoute;

        this.patientsByAuthenticatedPhysician = this.apiBaseRoute + "/physicians/authenticated/patients";

        this.patientsInvitesRoute = this.patientsRoute + "/invites";
        this.patientsInvitesUrl = this.apiBaseRoute + this.patientsInvitesRoute;

        this.authenticatedPatientRoute = this.patientsRoute + "/authenticated";
        this.authenticatedPatientUrl = this.apiBaseRoute + this.authenticatedPatientRoute;

        this.visitsRoute = "/visits";

        this.bffApiBaseRoute = "/bff/patient-management/v1.0";
        this.registeredPatientUrl = this.bffApiBaseRoute + "/patients/registered";
    }

    getChatUrl(patientUid) {
        return `${this.apiChatBaseRoute}/patients/${patientUid}/chat`;
    }

    getVisitUrl(visitUid) {
        return this.apiBaseRoute + this.visitsRoute + "/" + visitUid;
    }

    getPatientVisitsUrl(patryUid) {
        return this.apiBaseRoute + this.patientsRoute + "/" + patryUid + "/visits";
    }

    getPartientUrl(patientUid) {
        return `${this.apiBaseRoute}/patients/${patientUid}`;
    }

    /**
     * Returns the requested visit
     * @param {*} visitUid identifier of the visit to look for
     */
    async getVisitByUid(visitUid = null) {
        if (visitUid) {
            return ApiHelper.get(this.getVisitUrl(visitUid)).then((apiResp) => {
                if (apiResp.status < 400) {
                    const visit = apiResp.dataObject;
                    if (visit.comments?.length === 0) {
                        visit.comments = [factoryComment({partyUid: apiResp.patientUid, fullName: "", commentedOn: Date.now(), comment: ""})];
                    }
                    return Promise.resolve(visit);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.resolve(null);
    }

    /**
     * Returns the patient for the currently authenticated user
     */
    async getAuthenticatedPatient() {
        return ApiHelper.get(this.authenticatedPatientUrl).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    /**
     * Returns all patients for the currently authenticated physician
     */
    async getAllPatientsOfAPhysician() {
        return ApiHelper.get(this.patientsByAuthenticatedPhysician).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }

    /**
     * Returns the chat of a visit
     * @param {string} visitUid to look for chat in.
     */
    async getPatientChat(patientUid = null) {
        if (patientUid) {
            return ApiHelper.get(this.getChatUrl(patientUid)).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.resolve(null);
    }

    /**
     * 
     * @param {string} patientUid 
     * @param {object} message 
     */
    async postChatMessage(patientUid = null, message = null) {
        if (patientUid && message) {
            const msg = factoryMessage({ message: message });
            return ApiHelper.post(this.getChatUrl(patientUid), msg).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }

        return Promise.resolve(null);
    }

    async findPatientByVisit(visitUid = null) {
        if (visitUid) {
            return ApiHelper.get(this.patientsUrl + "?visitUid=" + visitUid).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.reject("visitUid cannot be null");
    }

    async searchPatient(sv = null) {
        if (sv) {
            return ApiHelper.get(this.patientsUrl + "?sv=" + sv).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.reject("Search value cannot be null");
    }

    /**
     * Returns the patient associated with the provided health record #
     * @param {String} hrUid health record number as String
     */
    async getPatientByHealthRecord(hrUid = null) {
        if (hrUid) {
            return ApiHelper.get(this.patientsUrl + "?sv=" + hrUid).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    console.error("getPatientByHealthRecord - API error")
                    return Promise.reject(error.dataObject);
                }
                //Network error
                console.error("getPatientByHealthRecord - Network error")
                return Promise.reject(error);
            });
        }
        return Promise.resolve(null);
    }

    /**
     * Create a patient with a State of pending registration. 
     * 
     * If an existing patient record existing with the same health record number, this api will simply return the existing one unchanged.
     * 
     * Once created, return the newly created patient record
     * 
     * @param {Ojbect} patient object representing a patient to invite 
     */
    async invitePatient(newPatient) {

        //Revise this call with a Promise
        return ApiHelper.post(this.patientsInvitesUrl, newPatient).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    };

    async getPatientVisits(patientUid = null) {
        if (patientUid) {
            return ApiHelper.get(this.getPatientVisitsUrl(patientUid)).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.resolve(null);
    }

    async savePatientVisit(visit = null) {
        if (visit) {
            return ApiHelper.post(this.getPatientVisitsUrl(visit.patientUid), visit).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
        return Promise.resolve(visit);
    }

    /**
     * Register a user wiht the help of the received token
     * @param {string} token to help the backend find the user and register
     */
    async registerNewPatient(token) {
        if (token) {
            return ApiHelper.post(this.registeredPatientUrl, token).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
    }

    /**
     * Find a patient matching the provided patient uid.
     * @param {String} patientUid the primary key of the patient
     * @returns the found patient
     */
    async findPatientByUid(patientUid) {
        if (patientUid) {
            return ApiHelper.get(this.getPartientUrl(patientUid)).then( (apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch( (error) => {
                if (error.dataObject) {
                    //Api error
                    return Promise.reject(error.dataObject);
                }

                //Network error
                return Promise.reject(error);
            });
        }
    }

    async updatePatient(patient) {
        if (patient) {
            return ApiHelper.post(this.getPartientUrl(patient.uid), patient).then((apiResp) => {
                if (apiResp.status < 400) {
                    return Promise.resolve(apiResp.dataObject);
                }
                return Promise.reject(apiResp.dataObject);
            }).catch((error) => {
                if (error.dataObject) {
                    // API Error
                    return Promise.reject(error.dataObject);
                }
                //Network error
                return Promise.reject(error);
            });
        }
    }
}

export const ApiPatientManagement = new PatientManagementApi();