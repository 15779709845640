import { ApiHelper } from './RestHelper';

class PhysicianManagementApi {

    constructor() {
        this.apiBaseRoute = "/party-management/v1.0";

        this.authenticatedPhysicianUri = `${this.apiBaseRoute}/physicians/authenticated`;
    }

    /**
     * Returns the requested visit
     * @param {*} visitUid identifier of the visit to look for
     */
    async getAuthenticatedPhysician() {
        return ApiHelper.get(this.authenticatedPhysicianUri).then((apiResp) => {
            if (apiResp.status < 400) {
                return Promise.resolve(apiResp.dataObject);
            }
            return Promise.reject(apiResp.dataObject);
        }).catch((error) => {
            if (error.dataObject) {
                // API Error
                return Promise.reject(error.dataObject);
            }
            //Network error
            return Promise.reject(error);
        });
    }
}

export const ApiPhysicianManagement = new PhysicianManagementApi();