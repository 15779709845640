import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Button, Card } from "react-bootstrap"
import { PatientLayout, PhysicianLayout } from "./layout";
import { Translate } from "./text";

export const PageNotFound = (props) => {
    return (
        <Alert variant="danger">
            <Alert.Heading><Translate>error.http.404.title</Translate></Alert.Heading>
            <p><Translate>error.http.404.message</Translate></p>
        </Alert>
    )
}

/**
 * Renders an alert based on the content of the apiError.  
 * @param {Object} params.apiError the error to display
 * @returns An alert component if an apiError is present, otherwise null
 */
export const ApiErrorMessage = ({ apiError }) => {

    if (apiError) {
        return (
            <Alert variant="danger">
                <Alert.Heading><Translate>generic.error</Translate></Alert.Heading>
                <ApiValidationErrorMessages apiError={apiError} />
            </Alert>
        );
    }
    //No errors to display
    return null;
}

/**
 * Renders the generic error messsage or the validation error messages if the validation errors has more than one message
 * @param {Object} params.apiError containing the server response from a fetch call
 * @returns renders the proper message based on the provided error
 */
function ApiValidationErrorMessages({ apiError }) {
    if (apiError) {
        if (apiError.error) {
            // is a validation error
            if (apiError.validationErrors) {
                if (apiError.validationErrors.length > 1) {
                    return apiError.validationErrors.map((vError, idx) => {
                        return (
                            <p key={idx}>{vError.formatedMessage}</p>
                        )
                    });
                }
                return (
                    <p>{apiError.message}</p>
                )
            }

            //It is a HTTP error
            return (
                <p>{apiError.status} - {apiError.error}</p>
            );
        }

        if (apiError.message) {
            return (
                <p>{apiError.message}</p>
            );
        }
    }
    //Nothing to display
    return null;
}

/**
 * @param {boolean} params.isShowing tells to render the message or not
 * @param {String} params.title is the title of the alert message.  Default to "Success"
 * @param {Number} params.duration in second to diplay the message. Default to 3sec.
 * @returns a render sucess message with the provided children and optionally the title.  This message will disappear in 3 sec, or the number of second provided as param
 */
export const ApiSuccessMessage = ({ isShowing = false, title = "Success", children }) => {

    if (isShowing) {
        return (
            <Alert variant="success">
                <Alert.Heading>{title}</Alert.Heading>
                <p>{children}</p>
            </Alert>
        );
    }

    //Nothing to render
    return null;
}

function DownForMaintenance(args) {
    const { logout } = useAuth0();
    return (
        <Card className="m-3">
            <Card.Img variant="top" src="/assets/maintenance_page.jpg" />
            <Card.Body className="">
                <p>Please logout and comme back later.</p>
                <div className="d-flex justify-content-between">
                    <div></div>
                    <Button variant="outline-primary" href="." onClick={(e) => { e.preventDefault(); logout({ returnTo: window.location.origin }) }}>Logout</Button>
                </div>
            </Card.Body>

        </Card>
    );
}

export function PatientDownForMaintenance() {
    return (
        <PatientLayout>
            <DownForMaintenance />
        </PatientLayout>
    );
}

export function PhysicianDownForMaintenance() {
    return (
        <PhysicianLayout>
            <DownForMaintenance />
        </PhysicianLayout>
    );
}