import Moment from 'moment';
import { Card, Form } from "react-bootstrap";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { usePatientContext } from "../../../components/context/patientContext";
import { factoryImage, VisitObj } from "../../../components/domain/visit";
import { NavButton } from "../../../components/ui/navigations";
import { ApiErrorMessage, ApiSuccessMessage } from "../../../components/ui/errors";
import { FormDataHandling } from "../../../components/utils/formUtils";
import { useEffect } from "react";
import { Link, useLocation } from "wouter";
import { PlaceHolderCardBody } from '../../../components/ui/loading';
import { NewVisitStepByStep } from '../../../components/ui/newVisit';
import { PhysicianVisitView } from '../../../components/ui/patientVisit';
import imageCompression from 'browser-image-compression';
import { Translate } from "../../../components/ui/text";

/**
 * Component to create a new visit form that will guide the user in creating a new visit for the current patient context
 * @returns A form to create a new visit for the provided patient id
 */
export const PhysicianNewVisitController = () => {
    const [{ patient, visit, apiError, isApiCallActive }, reducer] = usePatientContext();
    const [, navigate] = useLocation();

    //handle form submit
    const handleSubmit = (e) => {
        visit.patientUid = patient.uid;
        handleVisitSubmit(patient, visit, e, reducer, navigate);
    };

    const handleImageChange = (visit) => {
        reducer({ type: "visitStateChanged", value: visit });
    }

    const handleOnChange = (e) => {
        FormDataHandling.handleOnChange(e, visit);
        reducer({ type: "visitStateChanged", value: visit });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <Form noValidate onSubmit={handleSubmit}>
                <NewVisitStepByStep visit={visit} onVisitChange={handleOnChange} onImageChange={handleImageChange} isApiCallActive={isApiCallActive} />
            </Form>
        </>
    );
}

const fileOptions = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}

/**
 * Handle the form submission event of a new visit
 * @param {Object} event the onSubmit event object
 * @param {Function} disaptch the reducer function
 */
const handleVisitSubmit = (patient, visit = new VisitObj(), event, dispatch, navigate, actionType = "newVisitCreated", navigateTo = null) => {
    event.preventDefault();
    event.stopPropagation();

    //Assign patient to this visit and comments
    visit.patientUid = patient.uid;
    visit.comments[0].partyFullName = patient.fullName;
    visit.comments[0].partyUid = patient.uid;

    dispatch({type: "isApiCallActive"});

    //Process images and call the api
    Promise.all(visit.images.map((img) => {
        if (!img.imageData) {
            return imageCompression(img, { ...fileOptions }).then((cFile) => {
                return imageCompression.getDataUrlFromFile(cFile).then((dataUrl) => {
                    return Promise.resolve(factoryImage({ image: dataUrl, name: img.name, type: img.type }));
                });
            });
        }
        //Already a proper visit image
        return Promise.resolve(img);
    })).then((result) => {
        visit.images = result;

        ApiPatientManagement.savePatientVisit(visit).then((visit) => {
            if (visit) {
                dispatch({ type: actionType, value: visit });
                if (navigate) {
                    navigate(navigateTo ? navigateTo : `/private/physician/patients/${visit.patientUid}/records/`);
                }
            }
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        });
    }).catch((err) => {
        dispatch({ type: "apiError", value: err });
    });
}

/**
 * Component to display a visit for the provided visit id
 * @param {String} visitId of the visit to display
 * @returns a rendered visit view
 */
export const PhysicianVisitViewController = ({ patientId, visitId }) => {
    const [{ visit }, dispatch] = usePatientContext();

    //Fetch visit on first rendering
    useEffect(() => {
        if (visitId) {
            ApiPatientManagement.getVisitByUid(visitId).then((vResult) => {
                dispatch({ type: "visitStateChanged", value: vResult });
            }).catch((result) => {
                dispatch({ type: "apiError", value: result });
            })
        }
    }, [visitId]);

    if (visit && visit.uid) {
        return (
            <Card className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                        {Moment(visit.occuredOn).format("MMM Do, YYYY")} <small>({Moment(visit.occuredOn).fromNow()})</small>
                    </div>
                    <Link href={`/private/physician/patients/${patientId}/records/${visitId}/edit`}>
                        <NavButton variant="outline-primary"><i className="bi bi-pencil-square" /> <Translate>physician.patient.recrod.edit</Translate></NavButton>
                    </Link>
                </Card.Header>
                <div className="m-2">
                    <PhysicianVisitView visit={visit} />
                </div>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Header><Translate>physician.patient.recrod.loading</Translate></Card.Header>
            <PlaceHolderCardBody />
        </Card>
    )
}


export const PhysicianEditPatientVisitController = ({ visitId }) => {
    const [{ patient, visit, apiError, hasVisitBeenUpdated, isApiCallActive = false }, reducer] = usePatientContext();
    const [, navigate] = useLocation();

    //Fetch visit on first rendering
    useEffect(() => {
        if (visitId) {
            ApiPatientManagement.getVisitByUid(visitId).then((vResult) => {
                reducer({ type: "visitStateChanged", value: new VisitObj(vResult) });
            }).catch((result) => {
                reducer({ type: "apiError", value: result });
            })
        }
    }, [visitId]);

    //handle form submit
    const handleSubmit = (e) => {
        handleVisitSubmit(patient, visit, e, reducer, navigate, "visitEdited", `/private/physician/patients/${visit.patientUid}/records/${visitId}`);
    };

    const handleImageChange = (visit) => {
        reducer({ type: "visitStateChanged", value: visit });
    }

    const handleOnChange = (e) => {
        FormDataHandling.handleOnChange(e, visit);
        reducer({ type: "visitStateChanged", value: visit });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <ApiSuccessMessage isShowing={hasVisitBeenUpdated}><Translate>physician.patient.recrod.apiSuccess</Translate></ApiSuccessMessage>
            <Form noValidate onSubmit={handleSubmit}>
                <NewVisitStepByStep forPatient={false} visit={visit} onVisitChange={handleOnChange} onImageChange={handleImageChange}  isApiCallActive={isApiCallActive} />
            </Form>
        </>
    );
}