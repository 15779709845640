
import moment from "moment";
import { useEffect } from "react";
import { Button, Card, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Link, useLocation } from "wouter";
import { ApiPatientManagement } from "../../../components/api/apiPatientManagement";
import { usePatientContext } from "../../../components/context/patientContext";
import { factoryImage, VisitObj } from "../../../components/domain/visit";
import { ApiErrorMessage, ApiSuccessMessage } from "../../../components/ui/errors";
import { PlaceHolderCardBody } from "../../../components/ui/loading";
import { ControlledNavButton, NavButton, PageNavigator } from "../../../components/ui/navigations";
import { NewVisitStepByStep } from "../../../components/ui/newVisit";
import { PatientVisitListView, PatientVisitView } from "../../../components/ui/patientVisit";
import { FormDataHandling } from "../../../components/utils/formUtils";
import imageCompression from 'browser-image-compression';
import { Translate } from "../../../components/ui/text";

export const PatientVisitsListController = ({pageNo=0}) => {
    const [{ visits }, dispatch] = usePatientContext();

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between align-items-center" >
                <span ><Translate>patient.visit.pastRecords.title</Translate></span>
                <Link href="/private/patient/records/new">
                    <ControlledNavButton handleClick={(e) => dispatch({ type: "resetVisit" })} className="text-nowrap ms-1" variant="outline-primary">
                        <i className="bi bi-plus-square" /> <Translate>generic.new</Translate>
                    </ControlledNavButton>
                </Link>
            </Card.Header>
            <PatientVisitListView visits={visits} maxItems={7} currentPage={pageNo} basePath="/private/patient/records" />
            <PageNavigator numberOfItems={visits?.length} maxItems={7} currentPage={pageNo} baseUrl="/private/patient/records/"/>
        </Card>
    );
}

export const PatientVisitViewController = ({ visitId }) => {
    const [{ visit }, dispatch] = usePatientContext();

    //Fetch visit on first rendering
    useEffect(() => {
        if (visitId) {
            ApiPatientManagement.getVisitByUid(visitId).then((vResult) => {
                dispatch({ type: "visitStateChanged", value: vResult });
            }).catch((result) => {
                dispatch({ type: "apiError", value: result });
            })
        }
    }, [visitId]);

    if (visit && visit.uid) {
        return (
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                        {moment(visit.occuredOn).format("MMM Do, YYYY")} <small>({moment(visit.occuredOn).fromNow()})</small>
                    </div>
                    <EditVisitButtonView visit={visit} />
                </Card.Header>
                <div className="m-2">
                    <PatientVisitView visit={visit} />
                </div>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Header><Translate>patient.visit.loading</Translate></Card.Header>
            <PlaceHolderCardBody />
        </Card>
    )
}

const EditVisitButtonView = ({ visit }) => {

    if (visit && visit.occuredOn) {
        //Evaluate if the visit was created earlier than 24h.
        const isWithin24Hours = moment().diff(moment(visit.occuredOn), 'hours') < 25;

        const isAllowedToEdit = visit.patientUid === visit.lastUpdatedByPartyUid;

        if (isWithin24Hours && isAllowedToEdit) {
            //Allowed to edit, since within 24h
            return (
                <Link href={`/private/patient/records/${visit.uid}/edit`}>
                    <NavButton variant="outline-primary"><i className="bi bi-pencil-square" />&nbsp;<Translate>generic.edit</Translate></NavButton>
                </Link>
            );
        }
    }

    //Not allowed to edit
    return (
        <OverlayTrigger trigger="click" placement="left" overlay={ReadOnlyPopover}>
            <Button type="button" variant="outline-secondary"><i className="bi bi-info-circle"/></Button>
        </OverlayTrigger>
    );
};

const ReadOnlyPopover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3"><Translate>patient.visit.noEdit</Translate></Popover.Header>
        <Popover.Body><Translate>patient.visit.noEditMessage</Translate></Popover.Body>
    </Popover>
);

export const PatientNewVisitController = () => {
    const [{ patient, visit, apiError, isApiCallActive=false }, reducer] = usePatientContext();
    const [, navigate] = useLocation();

    //handle form submit
    const handleSubmit = (e) => {
        handleVisitSubmit(patient, visit, e, reducer, navigate);
    };

    const handleImageChange = (visit) => {
        reducer({ type: "visitStateChanged", value: visit });
    }

    const handleOnChange = (e) => {
        FormDataHandling.handleOnChange(e, visit);
        reducer({ type: "visitStateChanged", value: visit });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <Form noValidate onSubmit={handleSubmit}>
                <NewVisitStepByStep forPatient={true} visit={visit} onVisitChange={handleOnChange} onImageChange={handleImageChange} isApiCallActive={isApiCallActive}/>
            </Form>
        </>
    );
}

export const PatientEditVisitController = ({ visitId }) => {
    const [{ patient, visit, apiError, hasVisitBeenUpdated, isApiCallActive=false }, reducer] = usePatientContext();
    const [, navigate] = useLocation();

    //Fetch visit on first rendering
    useEffect(() => {
        if (visitId) {
            ApiPatientManagement.getVisitByUid(visitId).then((vResult) => {
                reducer({ type: "visitStateChanged", value: new VisitObj(vResult) });
            }).catch((result) => {
                reducer({ type: "apiError", value: result });
            })
        }
    }, [visitId]);

    //handle form submit
    const handleSubmit = (e) => {
        handleVisitSubmit(patient, visit, e, reducer, navigate, "visitEdited");
    };

    const handleImageChange = (visit) => {
        reducer({ type: "visitStateChanged", value: visit });
    }

    const handleOnChange = (e) => {
        FormDataHandling.handleOnChange(e, visit);
        reducer({ type: "visitStateChanged", value: visit });
    }

    return (
        <>
            <ApiErrorMessage apiError={apiError} />
            <ApiSuccessMessage isShowing={hasVisitBeenUpdated}><Translate>patient.visit.apiSuccess</Translate></ApiSuccessMessage>
            <Form noValidate onSubmit={handleSubmit}>
                <NewVisitStepByStep forPatient={true} visit={visit} onVisitChange={handleOnChange} onImageChange={handleImageChange} isApiCallActive={isApiCallActive}/>
            </Form>
        </>
    );
}

const fileOptions = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}

/**
 * Handle the form submission event of a new visit
 * @param {Object} event the onSubmit event object
 * @param {Function} disaptch the reducer function
 */
const handleVisitSubmit = (patient, visit = new VisitObj(), event, dispatch, navigate, actionType = "newVisitCreated") => {
    event.preventDefault();
    event.stopPropagation();

    dispatch({type: "isApiCallActive"});

    //Assign patient to this visit and comments
    visit.patientUid = patient.uid;
    visit.comments[0].partyFullName = patient.fullName;
    visit.comments[0].partyUid = patient.uid;

    //Process images and call the api
    Promise.all(visit.images.map((img) => {
        if (!img.imageData) {
            return imageCompression(img, { ...fileOptions }).then((cFile) => {
                return imageCompression.getDataUrlFromFile(cFile).then((dataUrl) => {
                    return Promise.resolve(factoryImage({ image: dataUrl, name: img.name, type: img.type }));
                });
            });
        }
        //Already a proper visit image
        return Promise.resolve(img);
    })).then((result) => {
        visit.images = result;

        ApiPatientManagement.savePatientVisit(visit).then((visit) => {
            if (visit) {
                dispatch({ type: actionType, value: visit });
                if (navigate) {
                    navigate("/private/patient/records/" + visit.uid);
                }
            }
        }).catch((result) => {
            dispatch({ type: "apiError", value: result });
        });
    }).catch((err) => {
        dispatch({ type: "apiError", value: err });
    });
}