import moment from "moment";
import React, { createContext, useContext, useReducer } from "react";
import { I18n } from "../utils/i18n";
import {MOMENT_CONFIG_FR} from "../../configs/moment-config";

const defaultReducer = (currentState, action) => {

    console.debug("LocalContext-Reducer - action & current state", action, currentState);

    switch (action.type) {
        case "changeLanguage":
            initLanguage(action.value);
            return {i18n: new I18n({lang: action.value})};
        default:
            console.error("Unknown reducer action", action);
            return {...currentState};
    }
}

export const LocalContext = createContext();

/**
 * Build the AuthenticatedUser object context for this app.  This is necessary to avoid spreading the IDP user object across our app in case we change IDP provider in the future
 * User must be authenticated before calling this context provider
 * @param {Object} user object from the IDP to build our internal user from 
 * @param {Object} children is the React children to render if the user is authenticated
 * @returns the provided children
 */
export const LocalContextProvider = ({ language = "en", children }) => {
    const ctx = useReducer(defaultReducer, {i18n: new I18n({lang: language})});
    console.debug("LocalContextProvier - Current context", ctx);
    
    //Initialize the language
    initLanguage(ctx[0].i18n.language);

    return (
        <LocalContext.Provider value={ctx}>
            {children}
        </LocalContext.Provider>
    );
}

export const useLocalContext = () => useContext(LocalContext);

const KEY_LANG = "locale.lang";

export function initLanguage(lang) {
    if (lang) {
        window.localStorage.setItem(KEY_LANG, lang);

       if (lang === "fr") {
        console.debug("Setting moment in French");
        moment.locale('fr', MOMENT_CONFIG_FR);
       } else {
        console.debug("Setting moment in English");
        moment.locale('en');
       }
    }
}

export function getLanguage() {
    return window.localStorage.getItem(KEY_LANG);
}