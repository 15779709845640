import { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, FormControl, InputGroup } from "react-bootstrap"
import { SpinnerWhenTrue } from "./loading";
import { Translate } from "./text";
import { useLocalContext } from "../context/LocalContext";

export const ChatView = ({ chat, onSend, onChange, senderPartyUid, message = "", isMessageBeingSent = false }) => {
    const [isHidden, toggleOverflow] = useState(true);
    const ovfRef = useRef(null);

    useEffect(() => {
        if (ovfRef && ovfRef.current) {
            ovfRef.current.scrollTop = 9999;
        }
    }, [ovfRef, isMessageBeingSent]);

    const [{ i18n }] = useLocalContext();

    if (chat) {
        return (
            <div>
                <Card className="mb-3">
                    <Card.Header className="d-flex justify-content-between">
                        <div><ChatRecipient patientUid={chat.patientUid} senderUid={senderPartyUid} /></div>
                        <div><Translate>view.chat.you</Translate></div>
                    </Card.Header>
                    <div ref={ovfRef} style={{ height: 350 }} className={"m-2 " + buildOverflowClass(isHidden)} onClick={(e) => toggleOverflow(!isHidden)}>
                        <ChatMessagesView messages={chat.messages} senderPartyUid={senderPartyUid} />
                    </div>
                    <div className="m-2">
                        <InputGroup>
                            <FormControl placeholder={i18n.getEntry("view.chat.messagePlaceHolder")} aria-label={i18n.getEntry("view.chat.messagePlaceHolder")} aria-describedby="basic-addon2" value={message} onChange={(e) => onChange(e.currentTarget.value)} />
                            <Button variant="primary" disabled={isMessageBeingSent} id="button-addon2" onClick={(e) => { e.preventDefault(); onSend(message) }}>
                                <SpinnerWhenTrue isLoading={isMessageBeingSent} loadingMessage={i18n.getEntry("view.chat.sending")}><i className="bi bi-send" /> <Translate>view.chat.send</Translate></SpinnerWhenTrue>
                            </Button>
                        </InputGroup>
                    </div>
                </Card>
            </div>
        )
    }

    //Nothing to render
    return null;
}

const ChatRecipient = ({ patientUid, senderUid }) => {
    return patientUid === senderUid ? (<Translate>generic.doctor</Translate>) : (<Translate>generic.patient</Translate>);
}

const ChatMessagesView = ({ messages, senderPartyUid }) => {

    if (messages && messages.length > 0) {
        return messages.map((msg, idx) => {
            return (
                <p key={idx} className={senderPartyUid === msg.partyUid ? "text-end ms-3 text-primary" : "text-start me-3"}>{msg.comment}</p>
            );
        });
    }

    //Nothing to render
    return (
        <Alert variant="info">
            <p><Translate>view.chat.noMessagesYet</Translate></p>
        </Alert>
    );
}

/**
 * 
 * @param {Boolean} isHidden 
 * @returns overflow-hidden when the provided value is true, othwerise overflow-auto
 */
function buildOverflowClass(isHidden) {
    return isHidden ? "overflow-hidden" : "overflow-auto";
}