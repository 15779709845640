
export class I18n {

    /**
     * 
     * @param {String} params.lang - Language to load form the resource location
     * @param {String} params.resLocationPath - Url path where the resources are located 
     */
        constructor({ lang }) {
        this.language = lang;
        this.initResource();
    }

    initResource() {
        this.resources = require(`../../configs/i18n-${this.language}.json`);
    }

    /**
     * 
     * @param {String} key of the translated text
     * @returns the translated text if the provided key was found, otherwise the provided key is returned
     */
    getEntry(key) {
        return this.resources[key] ? this.resources[key] : key;
    }

}