import { Accordion, Alert, Button, FloatingLabel, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { AccordionButton } from "./navigations";
import { factoryDataUrl } from '../domain/visit';
import { emptyIfUndefined } from "../utils/utils";
import { SpinnerWhenTrue } from "./loading";
import { Translate } from "./text";
import { useLocalContext } from "../context/LocalContext";

/**
 * 
 * @param {Object} params.visit to display and edit
 * @param {Function} params.onVisitChange callback when the state of the visit has changed
 * @param {Function} params.onImageChange callback when an image(s) has been selected
 * @param {Boolean} params.forPatient set to true to display the form for a patient, otherwise it will be displayed for a physician
 * @param {Boolean} params.isApiCallActive set to true to display disabled submit button with a spinner inside.
 * @returns a rendered 3 steps form to create or edit a visit.
 */
export const NewVisitStepByStep = ({ visit, onVisitChange, onImageChange, forPatient = false, isApiCallActive = false }) => {

    return (
        <Accordion defaultActiveKey="step1">
            <Accordion.Item eventKey="step1">
                <Accordion.Header><Translate>view.newVisit.step1</Translate></Accordion.Header>
                <Accordion.Body>
                    <CaptureImagesForPatientRecord visit={visit} onImageChange={onImageChange} />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="step2">
                <Accordion.Header><Translate>view.newVisit.step2</Translate></Accordion.Header>
                <Accordion.Body>
                    <CaptureWoundInfoForPatientRecord forPatient={forPatient} visit={visit} onVisitChange={onVisitChange} />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="step3">
                <Accordion.Header><Translate>view.newVisit.step3</Translate></Accordion.Header>
                <Accordion.Body>
                    <ReviewCapturedInfoForPatientRecord visit={visit} forPatient={forPatient} apiCallActive={isApiCallActive} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const CaptureImagesForPatientRecord = ({ visit, onImageChange }) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 3,
        onDrop: (files) => {
            visit.replaceImagesWith(files);
            onImageChange(visit);
        }
    });

    return (
        <>
            <Alert variant="secondary" className="mb-3">
                <ImageCaptureControlView filesLength={visit.images.length} rootProps={getRootProps} inputProps={getInputProps}>
                    <div >
                        <div className="d-flex justify-content-center">
                            <UploadedFiles files={visit.images} />
                        </div>
                    </div>
                </ImageCaptureControlView>
            </Alert>
            <div className="d-flex justify-content-between mt-3">
                <Button variant="outline-primary" className="py-1" onClick={() => { visit.resetImages(); onImageChange(visit) }}><i className="bi bi-x-square" />&nbsp;<Translate>view.newVisit.reset</Translate></Button>
                <AccordionButton eventKey="step2"><Translate>view.newVisit.next</Translate></AccordionButton>
            </div>
        </>
    );
}

const UploadedFiles = ({ files }) => {
    return files.map((file, idx) => {
        return (
            <div style={{ width: "25%" }} key={idx} >
                <Image thumbnail src={file.imageData ? factoryDataUrl(file.imageMimeType, file.imageData) : URL.createObjectURL(file)} />
            </div>
        );
    });
}

const ImageCaptureControlView = ({ filesLength, rootProps, inputProps, children }) => {
    return (
        <div {...rootProps({ className: 'dropzone' })}>
            <input {...inputProps()} />
            <UploadMessage filesLenght={filesLength} />
            {children}
        </div>
    );
}

const UploadMessage = ({ filesLenght }) => {
    if (filesLenght === 0) {
        return (
            <>
                <span className="d-flex justify-content-center align-items-center mb-1">
                    <h1 className="me-2"><i className="bi bi-camera" /></h1>
                    <Translate>view.newVisit.uploadMessage</Translate>
                </span>
            </>
        )
    }

    return (
        <>
            <span className="d-flex justify-content-center align-items-center mb-1">
                <h1 className="me-2"><i className="bi bi-camera" /></h1>
                {filesLenght < 3 && (<Translate>view.newVisit.uploadMessage.forLessThen3</Translate>)}
                {filesLenght === 3 && (<Translate>view.newVisit.uploadMessage.with3</Translate>)}
            </span>
        </>
    )
}
const CaptureWoundInfoForPatientRecord = ({ onVisitChange = null, forPatient = false, visit }) => {
    const [{ i18n }] = useLocalContext();

    return (
        <>
            <Form.Text className="text-muted">
                <Translate>view.newVisit.woundInfoMessage</Translate>
            </Form.Text>
            <div className="d-flex mb-3">
                <FloatingLabel controlId="fl.wound.xWidth" label={i18n.getEntry("view.newVisit.woundWidth")} className="me-2" >
                    <Form.Control type="number" name="wound.xWidth" placeholder={i18n.getEntry("view.newVisit.woundWidth")} onChange={onVisitChange} value={visit.wound.xWidth} />
                </FloatingLabel>
                <div>&nbsp;</div>
                <FloatingLabel controlId="fl.wound.yheight" label={i18n.getEntry("view.newVisit.woundHeight")}>
                    <Form.Control type="number" name="wound.yHeight" placeholder={i18n.getEntry("view.newVisit.woundHeight")} onChange={onVisitChange} value={visit.wound.yHeight} />
                </FloatingLabel>
            </div>
            <div className="mb-3">
                <Form.Group controlId="phys-note">
                    <Form.Text className="text-muted">
                        {forPatient ? (<Translate>view.newVisit.woundDoctorObservation</Translate>) : (<Translate>view.newVisit.woundPatientObservation</Translate>)}
                    </Form.Text>
                    {!forPatient && <Form.Control as="textarea" name="physicianNotes.comment" placeholder={i18n.getEntry("view.newVisit.doctorsNotes")} rows="4" onChange={onVisitChange} value={emptyIfUndefined(visit.physicianNotes.comment)} />}
                    {forPatient && <Form.Control as="textarea" name="comments.0.comment" placeholder={i18n.getEntry("view.newVisit.patientsNotes")} rows="4" onChange={onVisitChange} value={emptyIfUndefined(visit.comments[0].comment)} />}
                </Form.Group>
            </div>
            <div className="d-flex justify-content-end">
                <AccordionButton eventKey="step3"><Translate>view.newVisit.next</Translate></AccordionButton>
            </div>
        </>
    )
}

const ReviewCapturedInfoForPatientRecord = ({ visit = null, forPatient = false, apiCallActive = false }) => {
    const [{ i18n }] = useLocalContext();

    if (visit?.images?.length > 0) {
        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className="text-muted"><Translate>view.newVisit.woundDimension</Translate></h6>
                        <div className="d-flex flex-wrap">
                            <p><i className="bi bi-rulers" /> {visit.wound.xWidth}mm x {visit.wound.yHeight}mm</p>
                        </div>
                    </div>
                    <div>
                        <Button type="submit" variant="outline-primary" className="d-flex flex-nowrap align-items-center" disabled={apiCallActive} >
                            <SpinnerWhenTrue isLoading={apiCallActive} loadingMessage={i18n.getEntry("view.newVisit.submiting")}><i className="bi bi-send" />&nbsp;<Translate>view.newVisit.submit</Translate></SpinnerWhenTrue>
                        </Button>
                    </div>
                </div>
                <h6 className="text-muted"><Translate>view.newVisit.yourObservation</Translate>:</h6>
                <p><i className="bi bi-chat-right-quote" />&nbsp;{!forPatient ? visit.physicianNotes.comment : visit.comments[0].comment}</p>
                <div className="d-flex justify-content-center">
                    <UploadedFiles files={visit.images} />
                </div>
            </>
        )
    }

    //No files
    return (
        <p><Translate>view.newVisit.atLeastOneImage</Translate></p>
    );

}
